import {
  ExecutionWeekPlanningStore,
  executionWeekPlanningStore,
} from './execution-week-planning.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import SnackError from 'utils/models/snackError';

export class ExecutionWeekPlanningService {
  constructor(private store: ExecutionWeekPlanningStore) {}

  fetchExecutionPlanning = async (
    from: string,
    to: string,
    orgId?: ID,
    orgUnitId?: ID,
    requestFilters?: { [key: string]: string | undefined }
  ) => {
    if (!orgId) {
      this.store.setLoading(false);
      return;
    }
    this.store.setLoading(true);

    const [planning, e] = await resolve(
      metawasteApi.getPlanning(from, to, orgId, orgUnitId, requestFilters)
    );
    this.store.setLoading(false);
    if (e || !planning) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    this.store.set(planning);
  };

  onSelectedWastes = (newValue: ID[]) => {
    this.store.update((state) => ({
      ...state,
      filteredWasteIds: newValue,
    }));
  };
}

export const executionWeekPlanningService = new ExecutionWeekPlanningService(
  executionWeekPlanningStore
);
