import { CreateExecutionStore, createExecutionStore } from './create-execution.store';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { resolve } from 'utils/data/promise-utils';
import {
  formatToServiceKindLight,
  formatToServiceKindLightWithDisplay,
  ServiceKindLightDto,
} from 'api/dto/services/ServiceKindLightDto';
import { ID } from '@datorama/akita';
import {
  formatToServiceWasteContainer,
  ServiceWasteContainerDto,
} from 'api/dto/services/ServiceWasteContainerDto';
import {
  CreateExecutionOpenModalOptions,
  ExecutionDetailsUi,
  initExecutionDetails,
  initExecutionDetailsFromBookmark,
  initExecutionDetailsFromDto,
  initExecutionDetailsFromParent,
} from './create-execution.model';
import { organisationsQuery, OrganisationsQuery } from 'store/organisation/organisations';
import {
  organisationUnitsQuery,
  OrganisationUnitsQuery,
} from 'store/organisation/organisationUnits';
import { formatToExecutionCreationDto } from 'api/dto/execution/ExecutionCreationDto';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { formatToExecutionUpdateDto } from 'api/dto/execution/ExecutionUpdateDto';
import dayjs, {
  checkValidDate,
  combineDateAndHours,
  combineDateAndHoursToDate,
} from 'utils/data/dayjs.utils';
import { formatToExecutionCompleteDto } from 'api/dto/execution/ExecutionCompleteDto';
import { CommentExecutionService, commentExecutionServices } from '../executionComment';
import { createExecutionQuery, CreateExecutionQuery } from './create-execution.query';
import { SnackbarService, snackbarService } from 'store/snackbar';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import { executionDocumentsService, ExecutionDocumentsService } from '../executionDocuments';
import i18n from 'utils/data/i18n.utils';
import env from 'utils/env';
import { isBefore } from 'utils/dates/format';
import { executionIssueService, ExecutionIssueService } from '../executionIssue';
import { isToday, isTomorrow } from 'date-fns';
import { formatToExecutionUpdateTimeDto } from 'api/dto/execution/ExecutionUpdateTimeDto';
import { ServiceKindTypeEnum } from 'utils/enums/ServiceKindTypeEnum';
import { ResponseError } from 'utils/errors/response.error';
import { BookmarkServiceDto, bookmarkStore } from '../bookmark';
import { sessionQuery, SessionQuery } from 'store/session';

export class CreateExecutionService {
  constructor(
    private store: CreateExecutionStore,
    private organisationsQuery: OrganisationsQuery,
    private orgUnitsQuery: OrganisationUnitsQuery,
    private executionIssueService: ExecutionIssueService,
    private commentExecutionService: CommentExecutionService,
    private createExecutionQuery: CreateExecutionQuery,
    private snackbarService: SnackbarService,
    private executionDocumentsService: ExecutionDocumentsService,
    private sessionQuery: SessionQuery
  ) {}

  openModal = async (
    executionId?: ID,
    options?: CreateExecutionOpenModalOptions,
    bookmarkServices?: BookmarkServiceDto[]
  ) => {
    const activeClientOrgUnitId = this.orgUnitsQuery.activeOrgUnitId;
    this.store.setIsModalOpen(true);
    this.store.setLoading(true);
    if (!executionId && activeClientOrgUnitId) {
      await this.onOpenModalCreation(activeClientOrgUnitId, bookmarkServices, options);
    } else if (executionId) {
      await this.onOpenModalFetchGroup(executionId, activeClientOrgUnitId, options);
    } else {
      await this.onOpenModalCreation(undefined, bookmarkServices, options);
    }
    this.store.setLoading(false);
  };

  closeModal = () => {
    this.executionIssueService.resetStore();
    this.commentExecutionService.resetStore();
    this.executionDocumentsService.resetStore();
    bookmarkStore.isEditModalOpen(false);
    this.store.reset();
  };

  addNewExecution = () => {
    const mainExecution = this.store.getValue().entities?.[this.store.getValue().parentExecutionId];
    if (!mainExecution) {
      return;
    }
    const newExecution = initExecutionDetailsFromParent(mainExecution);
    this.store.upsertMany([newExecution]);
    this.store.update((state) => ({ ...state, matchingServices: undefined }));
  };

  removeChildExecution = (executionId: ID) => {
    this.store.remove(executionId);
  };

  private getServiceKindsSorted = (serviceKinds: ServiceKindLightDto[]) => {
    if (
      serviceKinds.some((kind) => kind.code === env.SERVICE_KIND_COLLECTE_CODE) &&
      serviceKinds.some((kind) => kind.code === env.SERVICE_KIND_COLLECTE_FORFAIT_CODE)
    ) {
      return serviceKinds.map(formatToServiceKindLightWithDisplay);
    }
    return serviceKinds.map(formatToServiceKindLight);
  };

  fetchExecutionsServicesKind = async (clientOrgUnitId: ID) => {
    const [serviceKinds, e] = await resolve(metawasteApi.getServiceKinds(clientOrgUnitId));
    if (e || !serviceKinds) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.serviceKinds'));
    }
    this.store.update((execution) => ({
      ...execution,
      serviceKinds: this.getServiceKindsSorted(serviceKinds),
    }));
  };

  private getPickupServices = async (
    clientOrgUnitId: ID,
    supplierOrgUnitId?: ID
  ): Promise<ServiceWasteContainerDto[]> => {
    const [services, e] = await resolve(
      metawasteApi.getServices(clientOrgUnitId, {
        serviceKindType: ServiceKindTypeEnum.PICKUP,
        supplierOrgUnitId,
      })
    );

    if (e || !services) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.defaultServices'));
    }

    return services;
  };

  private getDefaultServices = async (
    clientOrgUnitId: ID,
    serviceKindId: ID
  ): Promise<ServiceWasteContainerDto[]> => {
    const [services, e] = await resolve(
      metawasteApi.getServices(clientOrgUnitId, { serviceKindId: serviceKindId })
    );
    if (e || !services) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.defaultServices'));
    }
    return services;
  };

  private getFusionnedServices = async (clientOrgUnitId: ID, serviceKindIds: ID[]) => {
    const services = await Promise.all(
      serviceKindIds.map((it) => this.getDefaultServices(clientOrgUnitId, it))
    );
    this.store.update((execution) => ({
      ...execution,
      services: services.flatMap((it) => it.map(formatToServiceWasteContainer)),
    }));
  };

  fetchClientsFromSupplierUnit = async (supplierOrgUnitId: ID) => {
    const [services, e] = await resolve(metawasteApi.getClientsFromSupplierUnit(supplierOrgUnitId));
    if (e || !services) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.defaultServices'));
    }
    return services;
  };

  fetchPickupServices = async (
    clientOrgUnitId: ID,
    updateExecutions = true,
    supplierOrgUnitId?: ID
  ) => {
    const services = await this.getPickupServices(clientOrgUnitId, supplierOrgUnitId);

    this.store.update((execution) => ({
      ...execution,
      services: services.map(formatToServiceWasteContainer),
    }));

    if (updateExecutions) {
      this.store.update(null, {
        wasteId: undefined,
        containerId: undefined,
        serviceId: undefined,
        haulingOrganisationUnitName: undefined,
        destinationOrganisationUnitName: undefined,
        availableServicesForHaulier: undefined,
      });
    }
  };

  fetchExecutionsServices = async (
    clientOrgUnitId: ID,
    serviceKindId: ID,
    updateExecutions = true
  ) => {
    const serviceKinds = this.store.getValue().serviceKinds;
    const fusionnedCodes = [env.SERVICE_KIND_COLLECTE_CODE, env.SERVICE_KIND_COLLECTE_FORFAIT_CODE];
    const serviceFusionned = serviceKinds?.filter((serviceKind) =>
      fusionnedCodes.some((it) => serviceKind.code === it)
    );

    const serviceKindCode = serviceKinds?.find((it) => it.id === serviceKindId)?.code;
    if (serviceFusionned?.length === 2 && fusionnedCodes.some((it) => it === serviceKindCode)) {
      await this.getFusionnedServices(
        clientOrgUnitId,
        serviceFusionned.map((it) => it.id)
      );
    } else {
      const services = await this.getDefaultServices(clientOrgUnitId, serviceKindId);
      this.store.update((execution) => ({
        ...execution,
        services: services.map(formatToServiceWasteContainer),
      }));
    }

    if (updateExecutions) {
      this.store.update(null, {
        wasteId: undefined,
        containerId: undefined,
        serviceId: undefined,
        haulingOrganisationUnitName: undefined,
        destinationOrganisationUnitName: undefined,
        availableServicesForHaulier: undefined,
      });
    }
  };

  setExecutionDetail = (value: ExecutionDetailsUi) => {
    this.store.update(value.id, value);
  };

  setServiceId = async (
    executionId: ID,
    wasteId: ID | undefined,
    containerId: ID | undefined,
    pickedServiceId: ID | undefined
  ) => {
    let serviceId;

    if (!pickedServiceId) {
      const services = this.store.getValue().services;
      if (!services) {
        return;
      }
      const matchingServices = services.filter((service) => {
        if (wasteId && containerId) {
          return service.container?.id === containerId && service.waste?.id === wasteId;
        } else if (wasteId) {
          return service.waste?.id === wasteId && service.container == null;
        } else if (containerId) {
          return service.container?.id === containerId && service.waste == null;
        }
        return false;
      });
      if (matchingServices.length == 0) {
        return;
      }
      if (matchingServices.length > 0) {
        this.store.update((state) => ({
          ...state,
          matchingServices: matchingServices,
        }));
      }

      if (matchingServices.length === 1) {
        serviceId = matchingServices[0].id;
      } else {
        return;
      }
    } else {
      serviceId = pickedServiceId;
    }

    if (this.store.getValue().parentExecutionId === executionId) {
      const mainExecution = Object.values(this.store.getValue().entities ?? {}).find(
        (it) => it.parentExecutionId === undefined
      );
      if (mainExecution?.dto?.organisationUnit?.id) {
        return await this.fetchExecutionsHaulingDestination(
          executionId,
          mainExecution.dto.organisationUnit.id,
          serviceId ?? ''
        );
      } else {
        const activeClientUnit = this.orgUnitsQuery.activeOrgUnitId;
        const isMorgan = this.sessionQuery.isMorgan;
        return await this.fetchExecutionsHaulingDestination(
          executionId,
          isMorgan ? activeClientUnit ?? '' : createExecutionStore.getSelectedClientUnit() ?? '',
          serviceId ?? ''
        );
      }
    } else {
      this.store.update(executionId, (state) => ({
        ...state,
        serviceId,
      }));
    }
  };

  private formatExecutionsBeforeSave = (executions: ExecutionDetailsUi[]): ExecutionDetailsUi[] => {
    const mainExecutionDetails = executions[0];
    const childrenExecutionsDetails = [...executions.slice(1)];
    const formattedChildren = childrenExecutionsDetails.map((exec) => ({
      ...exec,
      plannedFrom: mainExecutionDetails.plannedFrom,
      plannedTo: mainExecutionDetails.plannedTo,
      selectedDate: mainExecutionDetails.selectedDate,
    }));
    return [mainExecutionDetails, ...formattedChildren];
  };

  save = async (executions: ExecutionDetailsUi[], force = false): Promise<string> => {
    const vExecutions = this.formatExecutionsBeforeSave(executions);
    vExecutions.forEach((execution) => {
      const executionStatus = execution.dto?.status;
      if (execution.asap !== true) {
        if (!checkValidDate(execution.plannedFrom)) {
          throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorFrom'));
        } else if (!checkValidDate(execution.plannedTo)) {
          throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorTo'));
        } else if (
          !executionStatus ||
          [ExecutionStatusEnum.CREATED].some((it) => it === executionStatus)
        ) {
          if (!execution.selectedDate || !dayjs(execution.selectedDate).isValid()) {
            throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorWrongDate'));
          } else if (isBefore(execution.selectedDate) && !this.sessionQuery.isPatrick) {
            throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorTooLate'));
          } else if (
            isToday(execution.selectedDate!) ||
            (isTomorrow(execution.selectedDate!) &&
              new Date().getHours() >= 14 &&
              !this.sessionQuery.isPatrick)
          ) {
            if (!executionStatus) {
              throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorTooLate'));
            }
            if (
              executionStatus === ExecutionStatusEnum.CREATED &&
              execution.dto?.plannedFrom !== execution.plannedFrom
            ) {
              throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorTooLate'));
            }
          }
        }
      }
      if (!execution.serviceId) {
        throw handleBasicSnackError(i18n.t('fe.executionModal.messages.errorService'));
      }
    });

    this.store.setIsSaving(true);
    const mainExecutionStatus = vExecutions[0].dto?.status;
    if (mainExecutionStatus) {
      if (mainExecutionStatus === ExecutionStatusEnum.CREATED) {
        await this.updateExecutions(vExecutions);
      } else if (mainExecutionStatus === ExecutionStatusEnum.REJECTED) {
        await this.editRejectedExecutions(vExecutions);
      } else if (mainExecutionStatus === ExecutionStatusEnum.EXECUTED) {
        await this.editCompletedExecutions(vExecutions);
      }
    } else {
      await this.createExecutions(vExecutions, force);
    }
    this.store.setIsSaving(false);
    this.store.reloadExecutions();
    this.closeModal();
    if (mainExecutionStatus) {
      return i18n.t('fe.executionModal.messages.successUpdate');
    } else {
      return i18n.t('fe.executionModal.messages.successCreate');
    }
  };

  startMarkAsComplete = () => {
    this.store.update(null, { wasteAmount: -1, executedAt: dayjs().hour(12).minute(0).toDate() });
  };

  validateMarkAsComplete = async (executions: ExecutionDetailsUi[]) => {
    const mainExecution = executions[0];
    const mainExecutionDetails: ExecutionDetailsUi = {
      ...mainExecution,
      executedAt: combineDateAndHoursToDate(
        mainExecution.plannedFrom,
        mainExecution.executedAt ?? new Date()
      ),
    };
    const childrenExecutionsDetails = [...executions.slice(1)].map(
      (execution): ExecutionDetailsUi => ({
        ...execution,
        executedAt: mainExecutionDetails.executedAt,
      })
    );
    const executionsFormatted = [mainExecutionDetails, ...childrenExecutionsDetails];
    this.store.setIsSavingMarkAsComplete(true);
    await Promise.all(
      executionsFormatted.map((executionFormatted) => {
        resolve(this.executionDocumentsService.updateDocumentsForExecution(executionFormatted.id));
        resolve(
          metawasteApi.patchCompleteExecution(
            executionFormatted.id,
            formatToExecutionCompleteDto(executionFormatted)
          )
        );
      })
    );
    this.store.setIsSavingMarkAsComplete(false);
    this.store.reloadExecutions();
    this.closeModal();
  };

  startProposeNewTime = (execution: ExecutionDetailsUi) => {
    this.store.setHighlightDates(true);
    if (!execution.plannedFrom || !execution.plannedTo) {
      this.store.update(execution.id, {
        plannedFrom: dayjs().set('hour', 8).set('minute', 0).toDate(),
        plannedTo: dayjs().set('hour', 17).set('minute', 0).toDate(),
      });
    }
  };

  acceptOrRejectExecution = async (
    executions: ExecutionDetailsUi[],
    accepted: boolean
  ): Promise<string> => {
    const mainExecution = executions[0];
    const { selectedDate, plannedFrom, plannedTo } = mainExecution;
    const justification = mainExecution.justification;
    const plannedFromString = combineDateAndHours(selectedDate, plannedFrom);
    if (
      !selectedDate ||
      new Date(plannedFromString) < dayjs(mainExecution.createdAt).startOf('day').toDate() ||
      new Date(plannedFromString) < dayjs().startOf('day').toDate()
    ) {
      throw handleBasicSnackError(i18n.t('fe.executionModal.messages.patrick.errorWrongDate'));
    }

    await Promise.all(
      executions.map((e) =>
        resolve(this.executionDocumentsService.updateDocumentsForExecution(e.id))
      )
    );

    const [_, e] = await resolve(
      metawasteApi.patchAcceptOrRejectExecution(mainExecution.id, {
        accepted,
        justification,
        plannedFrom:
          selectedDate && plannedFrom ? combineDateAndHours(selectedDate, plannedFrom) : undefined,
        plannedTo:
          selectedDate && plannedTo ? combineDateAndHours(selectedDate, plannedTo) : undefined,
      })
    );
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.execution.acceptOrReject'));
    }
    this.store.reloadExecutions();
    this.closeModal();
    if (accepted) {
      return i18n.t('fe.executionModal.messages.executionAccepted');
    } else {
      return i18n.t('fe.executionModal.messages.executionDenied');
    }
  };

  cancelExecution = async (mainExecution?: ExecutionDetailsUi) => {
    if (!mainExecution) {
      return;
    }
    const callApi =
      mainExecution.dto?.status === ExecutionStatusEnum.CREATED
        ? metawasteApi.deleteExecution(mainExecution.id)
        : metawasteApi.patchArchiveExecution(mainExecution.id);
    const [, e] = await resolve(callApi);
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.execution.cancel'));
    }
    this.snackbarService.showMessage(i18n.t('fe.executionModal.messages.successCancel'), {
      variant: 'success',
    });
    this.store.reloadExecutions();
    this.closeModal();
  };

  patchExecutionMorganNewTime = async (mainExecution?: ExecutionDetailsUi) => {
    if (!mainExecution) {
      return;
    }
    const [, e] = await resolve(
      metawasteApi.patchExecutionTime(
        mainExecution.id,
        formatToExecutionUpdateTimeDto(mainExecution)
      )
    );
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.execution.morganNewTime'));
    }
    this.snackbarService.showMessage(i18n.t('fe.executionModal.messages.successMorganNewTime'), {
      variant: 'success',
    });
    this.store.reloadExecutions();
    this.closeModal();
  };

  private updateExecutions = async (executions: ExecutionDetailsUi[]): Promise<any> => {
    const mainExecutionDetails = executions[0];
    await this.executionDocumentsService.updateDocumentsForExecution(mainExecutionDetails.id);
    const [mainExecution, e] = await resolve(
      metawasteApi.putExecution(
        mainExecutionDetails.id,
        formatToExecutionUpdateDto(mainExecutionDetails)
      )
    );
    if (e || !mainExecution) {
      this.store.setIsSaving(false);
      throw handleBasicSnackError(i18n.t('fe.errors.execution.update'));
    }
    const childrenExecutionsDetails = [...executions.slice(1)];
    await this.updateOrCreateChildrenExecutions(mainExecutionDetails, childrenExecutionsDetails);
  };

  private editCompletedExecutions = async (executions: ExecutionDetailsUi[]) => {
    const mainExecution = executions[0];
    const mainExecutionDetails: ExecutionDetailsUi = {
      ...mainExecution,
      executedAt: combineDateAndHoursToDate(
        mainExecution.plannedFrom,
        mainExecution.executedAt ?? new Date()
      ),
    };
    const childrenExecutionsDetails = [...executions.slice(1)].map(
      (execution): ExecutionDetailsUi => ({
        ...execution,
        executedAt: mainExecutionDetails.executedAt,
      })
    );
    const executionsFormatted = [mainExecutionDetails, ...childrenExecutionsDetails];
    await Promise.all(
      executionsFormatted.map((executionFormatted) => {
        resolve(this.executionDocumentsService.updateDocumentsForExecution(executionFormatted.id));
        resolve(
          metawasteApi.putEditCompleteExecution(
            executionFormatted.id,
            formatToExecutionCompleteDto(executionFormatted)
          )
        );
      })
    );
  };

  private editRejectedExecutions = async (executions: ExecutionDetailsUi[]): Promise<any> => {
    const mainExecutionDetails = executions[0];
    await resolve(
      this.executionDocumentsService.updateDocumentsForExecution(mainExecutionDetails.id)
    );
    const [_, e] = await resolve(
      metawasteApi.putEditExecutionRejected(
        mainExecutionDetails.id,
        formatToExecutionUpdateDto(mainExecutionDetails)
      )
    );
    if (e) {
      this.store.setIsSaving(false);
      throw handleBasicSnackError(i18n.t('fe.errors.execution.editOrReject'));
    }
    const childrenExecutionsDetails = [...executions.slice(1)];
    await this.updateOrCreateChildrenExecutions(mainExecutionDetails, childrenExecutionsDetails);
  };

  private updateOrCreateChildrenExecutions = async (
    mainExecutionDetails: ExecutionDetailsUi,
    childrenExecutionsDetails: ExecutionDetailsUi[]
  ) => {
    await Promise.all(
      childrenExecutionsDetails.map((childExecutionsDetails) => {
        if (childExecutionsDetails.dto?.status) {
          resolve(
            this.executionDocumentsService.updateDocumentsForExecution(childExecutionsDetails.id)
          );
          resolve(
            metawasteApi.putExecution(
              childExecutionsDetails.id,
              formatToExecutionUpdateDto(childExecutionsDetails)
            )
          );
        } else {
          resolve(
            metawasteApi.createExecution(
              formatToExecutionCreationDto(
                childExecutionsDetails,
                mainExecutionDetails.id,
                this.executionDocumentsService.documentIdsForExecution(childExecutionsDetails.id)
              )
            )
          );
        }
      })
    );
  };

  private createExecutions = async (
    executions: ExecutionDetailsUi[],
    force = false
  ): Promise<any> => {
    const mainExecutionDetails = executions[0];
    const [mainExecution, e] = await resolve(
      metawasteApi.createExecution(
        formatToExecutionCreationDto(
          mainExecutionDetails,
          undefined,
          this.executionDocumentsService.documentIdsForExecution(mainExecutionDetails.id),
          force
        )
      )
    );
    if (e || !mainExecution) {
      this.store.setIsSaving(false);
      if (e instanceof ResponseError) {
        if (e.message === 'IDENTICAL_EXECUTIONS_FOUND') {
          const identicalExecutions = (e as ResponseError).data;

          this.store.setIdenticalExecutions(identicalExecutions);
        }
        throw handleBasicSnackError(i18n.t('fe.errors.execution.similar'));
      } else {
        throw handleBasicSnackError(i18n.t('fe.errors.execution.create'));
      }
    }
    const childrenExecutionsDetails = [...executions.slice(1)];
    for (const childExecutionsDetails of childrenExecutionsDetails) {
      await resolve(
        metawasteApi.createExecution(
          formatToExecutionCreationDto(
            childExecutionsDetails,
            mainExecution.id,
            this.executionDocumentsService.documentIdsForExecution(childExecutionsDetails.id),
            force
          )
        )
      );
    }
  };

  private onOpenModalCreation = async (
    clientOrgUnitId?: ID,
    bookmarkServices?: BookmarkServiceDto[],
    options?: CreateExecutionOpenModalOptions
  ): Promise<void> => {
    let mainExecution: ExecutionDetailsUi;
    const plannedFrom = dayjs().hour(8).minute(0);
    const plannedTo = dayjs().hour(12).minute(0).toDate();
    if (options?.hourSelected) {
      plannedTo.setHours(options.hourSelected?.getHours() + 1);
    }

    if (bookmarkServices && clientOrgUnitId) {
      const tmpBookmarkServices = [...bookmarkServices];
      mainExecution = initExecutionDetailsFromBookmark(
        tmpBookmarkServices[0],
        options?.hourSelected ? options.hourSelected : plannedFrom.toDate(),
        plannedTo
      );
      const childrenExecutionsDetails = tmpBookmarkServices
        .splice(1, tmpBookmarkServices.length)
        .map((b) =>
          initExecutionDetailsFromBookmark(b, plannedFrom.toDate(), plannedTo, mainExecution.id)
        );
      this.store.setMainExecution(mainExecution);
      this.store.upsertMany(childrenExecutionsDetails);
      await this.setServiceId(
        mainExecution.id,
        mainExecution.wasteId,
        mainExecution.containerId,
        mainExecution.serviceId
      );
      await this.fetchPickupServices(clientOrgUnitId, false);
    } else if (clientOrgUnitId) {
      mainExecution = initExecutionDetails(
        options?.hourSelected ? options.hourSelected : plannedFrom.toDate(),
        plannedTo,
        1,
        options?.dateSelected
      );

      if (this.sessionQuery.isMorgan) {
        await this.fetchPickupServices(clientOrgUnitId);
      } else {
        const clients = await this.fetchClientsFromSupplierUnit(clientOrgUnitId);
        this.store.setClientUnits(clients);
        mainExecution.asap = false;
        mainExecution.status = ExecutionStatusEnum.EXECUTED;
      }
      this.store.setMainExecution(mainExecution);
    } else {
      mainExecution = initExecutionDetails(
        options?.hourSelected ? options.hourSelected : plannedFrom.toDate(),
        plannedTo,
        1,
        options?.dateSelected
      );
      this.store.setMainExecution(mainExecution);
    }
  };

  private onOpenModalFetchGroup = async (
    executionId: ID,
    orgUnitId?: ID,
    options?: CreateExecutionOpenModalOptions
  ): Promise<void> => {
    const [executions, e] = await resolve(metawasteApi.getExecution(executionId));
    const mainExecution = executions?.find(
      (exec) => exec.id === exec.executionGroup?.mainExecutionId || !exec.executionGroup
    );
    if (e || !executions || !mainExecution) {
      this.closeModal();
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.execution'));
    }
    await this.setMainExecution(mainExecution, options);
    const childrenExecutionsDetails = executions
      .filter((it) => it.id !== mainExecution.id)
      .map(initExecutionDetailsFromDto);
    this.store.upsertMany(childrenExecutionsDetails);
  };

  private setMainExecution = async (
    mainExecution: ExecutionDto,
    options?: CreateExecutionOpenModalOptions
  ) => {
    const mainExecutionDetails = initExecutionDetailsFromDto(mainExecution);
    this.store.setMainExecution(mainExecutionDetails);
    if (options?.startRefuse === true) {
      this.startProposeNewTime(mainExecutionDetails);
    }
    if (this.createExecutionQuery.canFetchInfo === true) {
      await this.fetchInfoMainExecution(mainExecution, mainExecution.organisationUnit?.id ?? '');
    }
  };

  private fetchInfoMainExecution = async (mainExecution: ExecutionDto, orgUnitId: ID) => {
    let fetchServices: Promise<void> = Promise.resolve();
    if (
      [ExecutionStatusEnum.CREATED, ExecutionStatusEnum.REJECTED].some(
        (it) => mainExecution.status === it
      )
    ) {
      fetchServices = this.fetchExecutionsServices(orgUnitId, mainExecution.service.kind.id, false);
    }
    const fetchHaulingDestination = this.fetchExecutionsHaulingDestination(
      mainExecution.id,
      orgUnitId,
      mainExecution.service.id
    );
    await Promise.all([fetchServices, fetchHaulingDestination]);
  };

  private fetchExecutionsHaulingDestination = async (
    executionDetailId: ID,
    clientOrgUnitId: ID,
    serviceId: ID
  ) => {
    this.store.update((state) => ({
      ...state,
      haulingDestinationLoading: true,
    }));
    const [haulingDestination, e] = await resolve(
      metawasteApi.getHaulingDestination(clientOrgUnitId, serviceId)
    );
    if (e || !haulingDestination) {
      throw handleBasicSnackError(i18n.t('fe.errors.execution.hauling'));
    }
    this.store.update((state) => ({ ...state, haulingDestinationLoading: false }));
    this.store.update(executionDetailId, (state) => ({
      ...state,
      serviceId,
      haulingOrganisationUnitName: haulingDestination.haulingOrganisationUnitName,
      destinationOrganisationUnitName: haulingDestination.destinationOrganisationUnitName,
      haulingOrganisationId: haulingDestination.haulingOrganisationId,
      destinationOrganisationId: haulingDestination.destinationOrganisationId,
      haulingUnitId: haulingDestination.haulingOrganisationUnitId,
      destinationUnitId: haulingDestination.destinationOrganisationUnitId,
      availableServicesForHaulier: haulingDestination.availableServicesForHaulier.map(
        (it) => it.id
      ),
    }));
  };

  resetMatchingServices() {
    this.store.update((state) => ({
      ...state,
      matchingServices: undefined,
    }));
  }

  clearIdenticalExecutions() {
    this.store.update((state) => ({
      ...state,
      identicalExecutions: [],
    }));
  }
}

export const createExecutionService = new CreateExecutionService(
  createExecutionStore,
  organisationsQuery,
  organisationUnitsQuery,
  executionIssueService,
  commentExecutionServices,
  createExecutionQuery,
  snackbarService,
  executionDocumentsService,
  sessionQuery
);
