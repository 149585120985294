import React, { FC, useEffect, useState } from 'react';
import {
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { ReactComponent as ArrowDownLine } from 'assets/icons/rework/arrowDownLine.svg';
import {
  createExecutionQuery,
  createExecutionService,
  createExecutionStore,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import { Transitions } from 'style/materialStylings';
import { isMobile } from 'utils/isMobile';
import { ID } from '@datorama/akita';
import { useSnackbar } from 'notistack';
import ExecutionDateField from './infoFields/ExecutionDate.field';
import ExecutionServiceField from './infoFields/ExecutionService.field';
import ExecutionDestinationField from './infoFields/ExecutionDestination.field';
import ExecutionNbContainersField from './infoFields/ExecutionNbContainers.field';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import MaterialDropdownForm from 'components/MaterialDropdown/MaterialDropdownForm/MaterialDropdownForm';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';

interface ExecutionInfoFormProps {
  executionId: ID;
  updateExecutionField: (name: string) => (value: any) => void;
}

const styled: SxProps = {
  '& .MuiFilledInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: 'none !important',
  },
};

const ExecutionInfoForm: FC<ExecutionInfoFormProps> = (props) => {
  const { executionId, updateExecutionField } = props;

  const [execution, setExecution] = useState<ExecutionDetailsUi | undefined>(undefined);
  const [isExpanded, setIsExpanded] = useState(true);
  const [haulingDestinationLoading] = useObservable(
    createExecutionQuery.haulingDestinationLoading$
  );
  const [showSelectClients$] = useObservable(createExecutionQuery.showSelectClients$);

  const [activeOrganisationUnitId] = useObservable(organisationUnitsQuery.activeOrgUnitId$);
  const [availableClientsUnits] = useObservable(createExecutionQuery.availableClientsUnits$);
  const isMobiles = isMobile();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [clientUnit, setClientUnit] = useState<number>();
  const [client, setClient] = useState<number>();

  useEffect(() => {
    async function fetchData() {
      if (clientUnit)
        await createExecutionService.fetchPickupServices(
          clientUnit,
          true,
          activeOrganisationUnitId
        );
    }

    fetchData();
    if (clientUnit) createExecutionStore.setSelectedClientUnit(clientUnit);
  }, [clientUnit]);

  useEffect(() => {
    const _execution = createExecutionQuery.execution$(executionId).subscribe(setExecution);
    return () => {
      _execution.unsubscribe();
    };
  }, []);

  if (!execution) {
    return <>Error ExecutionInfo</>;
  }

  const availableClients: MaterialDropdownItem[] =
    availableClientsUnits
      ?.reduce((acc: MaterialDropdownItem[], curr) => {
        if (acc.some((o) => o.value === curr.clientOrganisationId)) {
          return acc;
        }
        return [
          ...acc,
          {
            value: curr.clientOrganisationId,
            label: curr.clientOrganisationName,
          },
        ];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const availableUnits: MaterialDropdownItem[] =
    availableClientsUnits
      ?.filter((acu) => acu.clientOrganisationId === client)
      .map((acu) => ({
        value: acu.clientOrganisationUnitId,
        label: acu.clientOrganisationUnitName,
      })) || [];

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {t('fe.executionModal.executionInfosForm.title')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            <ArrowDownLine
              width={14}
              height={14}
              style={{
                transform: isExpanded ? undefined : 'rotate(180deg)',
                transition: Transitions.cubic,
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={isExpanded} style={{ width: '100%', paddingTop: 6 }}>
        <Grid container rowSpacing={2}>
          {!execution.parentExecutionId && (
            <ExecutionDateField execution={execution} updateExecutionField={updateExecutionField} />
          )}
          {showSelectClients$ && (
            <>
              <Grid item xs={12}>
                <MaterialDropdownForm
                  label="Client"
                  color="secondary"
                  placeholder="Sélectionner"
                  handleChange={(value) => {
                    setClient(value);
                  }}
                  value={client ?? undefined}
                  items={availableClients}
                />
              </Grid>

              <Grid item xs={12}>
                <MaterialDropdownForm
                  label="Client Site"
                  color="secondary"
                  placeholder="Sélectionner"
                  disabled={!client}
                  handleChange={(value) => {
                    setClientUnit(value);
                  }}
                  value={clientUnit ?? undefined}
                  items={availableUnits}
                />
              </Grid>
            </>
          )}

          <>
            <ExecutionServiceField
              execution={execution}
              updateExecutionField={updateExecutionField}
            />
            <ExecutionNbContainersField
              execution={execution}
              updateExecutionField={updateExecutionField}
            />
          </>
          {haulingDestinationLoading ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '120px', width: '100%' }}
            >
              <CircularProgress size={30} />
            </Stack>
          ) : !execution.parentExecutionId &&
            execution.serviceId &&
            execution.containerId &&
            execution.wasteId &&
            execution.haulingOrganisationUnitName &&
            execution.destinationOrganisationUnitName ? (
            <ExecutionDestinationField execution={execution} />
          ) : null}
          {/*{execution.justification !== undefined && (*/}
          {/*  <ExecutionJustificationField*/}
          {/*    execution={execution}*/}
          {/*    updateExecutionField={updateExecutionField}*/}
          {/*  />*/}
          {/*)}*/}
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default ExecutionInfoForm;
