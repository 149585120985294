import i18n from 'utils/data/i18n.utils';

export const getCardStatisticData = () => {
  return [
    {
      title: i18n.t('fe.dashboard.morgan.stats.operations.card.operationTitle'),
      data: '...',
      devise: i18n.t('fe.dashboard.morgan.stats.operations.card.deviseName'),
      description: i18n.t('fe.dashboard.morgan.stats.operations.card.cardDescription'),
      dataStatistic: 0,
      cardNumber: 0,
      isLoading: false,
      canClick: true,
    },
    {
      title: i18n.t('fe.dashboard.morgan.stats.ecologic.card.ecologicTitle'),
      data: '...',
      devise: i18n.t('fe.dashboard.morgan.stats.ecologic.card.deviseName'),
      description: i18n.t('fe.dashboard.morgan.stats.ecologic.card.cardDescription'),
      dataStatistic: 0,
      cardNumber: 1,
      isLoading: false,
      canClick: true,
    },
    {
      title: i18n.t('fe.dashboard.morgan.stats.finance.card.financeTitle'),
      data: '...',
      devise: i18n.t('fe.dashboard.morgan.stats.finance.card.deviseName'),
      description: i18n.t('fe.dashboard.morgan.stats.finance.card.cardDescription'),
      dataStatistic: 0,
      cardNumber: 2,
      isLoading: false,
      canClick: true,
    },
  ];
};
