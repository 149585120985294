import { Grid } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import MaterialCheckBox from 'components/MaterialCheckBox/MaterialCheckBox';
import MaterialSingleDatePicker from 'components/MaterialDatePicker/MaterialSingleDatePicker/MaterialSingleDatePicker';
import MaterialTimeMultipleTextfield from 'components/MaterialTimeTextfield/MaterialTimeMultipleTextfield/MaterialTimeMultipleTextfield';
import MaterialTimeSingleTextfield from 'components/MaterialTimeTextfield/MaterialTimeSingleTextfield';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createExecutionQuery, ExecutionDetailsUi } from 'store/operations/createExecution';
import { Colors } from 'style/materialStylings';
import { sessionQuery } from 'store/session';

interface ExecutionDateFieldProps {
  execution: ExecutionDetailsUi;
  updateExecutionField: (name: string) => (value: any) => void;
}

const ExecutionDateField: FC<ExecutionDateFieldProps> = (props) => {
  const { execution, updateExecutionField } = props;

  const [canEditDate] = useObservable(createExecutionQuery.canEditDate$);
  const [canEditExecutedAtDate] = useObservable(createExecutionQuery.canEditExecutedAtDate$);
  const [highlightDates] = useObservable(createExecutionQuery.highlightDates$());
  const [canSeeAsap] = useObservable(createExecutionQuery.canSeeAsap$());
  const { t } = useTranslation();

  return (
    <>
      {canSeeAsap && (
        <Grid item xs={12}>
          <MaterialCheckBox
            handleChange={(value) => updateExecutionField('asap')(!value)}
            label={t('fe.executionModal.executionInfosForm.asapLabel')}
            readOnly={!canEditDate}
            value={!execution.asap ?? false}
          />
        </Grid>
      )}
      {(execution.asap !== true || !canSeeAsap) && (
        <>
          <Grid item xs={12}>
            <MaterialSingleDatePicker
              label={t('fe.executionModal.executionInfosForm.datePickerTitle')}
              value={execution.selectedDate ? new Date(execution.selectedDate) : undefined}
              color="secondary"
              handleChange={updateExecutionField('selectedDate')}
              withIcon={true}
              customStyle={{
                border: highlightDates ? `2px solid ${Colors.primary}` : undefined,
                borderRadius: 4,
              }}
              minDate={sessionQuery.isPatrick ? undefined : dayjs().startOf('day').toDate()}
              disabled={!canEditDate}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTimeMultipleTextfield
              color="secondary"
              label={t('fe.executionModal.executionInfosForm.datePickerLabel')}
              labelFrom={t('fe.misc.from')}
              labelTo={t('fe.misc.to')}
              valueFrom={execution.plannedFrom}
              valueTo={execution.plannedTo}
              handleChangeFrom={updateExecutionField('plannedFrom')}
              handleChangeTo={updateExecutionField('plannedTo')}
              customStyle={{
                border: highlightDates ? `2px solid ${Colors.primary}` : undefined,
                borderRadius: 4,
              }}
              readOnly={!canEditDate}
            />
          </Grid>
        </>
      )}
      {execution.executedAt !== undefined && (
        <Grid item xs={12}>
          <MaterialTimeSingleTextfield
            color="secondary"
            label={t('fe.executionModal.executionInfosForm.datePickerExecutedAt')}
            value={execution.executedAt}
            handleChange={updateExecutionField('executedAt')}
            readOnly={!canEditExecutedAtDate}
          />
        </Grid>
      )}
    </>
  );
};

export default ExecutionDateField;
