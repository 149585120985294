import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { OrganisationUnit } from './organisation-unit.model';
import { storageService } from 'utils/StorageService/StorageService';
import { LocalStorageKeyJson } from 'constants/LocalStorageKey';

export interface OrganisationUnitsState extends EntityState<OrganisationUnit> {
  activeOrgUnitId?: ID;
}

@StoreConfig({
  name: 'organisationUnits',
  resettable: true,
})
export class OrganisationUnitsStore extends EntityStore<OrganisationUnitsState> {
  constructor() {
    super();
    this.setActiveOrgUnitId(undefined);
  }

  setActiveOrgUnitId = (orgUnitId?: ID) => {
    const cacheOrgUnitId = storageService.getItemJson(
      LocalStorageKeyJson.OrganisationUnitId,
      undefined
    )?.orgUnitId;
    const vOrgUnitId = orgUnitId ?? cacheOrgUnitId;
    if (vOrgUnitId && this.getValue().entities?.[vOrgUnitId]) {
      this.setActive(vOrgUnitId);
    }
    // We want to update the state only if its different
    if (this.getValue().activeClientOrgUnitId !== vOrgUnitId) {
      this.update((state) => ({
        ...state,
        activeOrgUnitId: vOrgUnitId,
      }));
      storageService.setItemJson(LocalStorageKeyJson.OrganisationUnitId, {
        orgUnitId: vOrgUnitId,
      });
    }
  };

  removeActiveOrgUnit = () => {
    this.setActive(null);
    this.update((state) => ({
      ...state,
      activeOrgUnitId: undefined,
    }));
    storageService.removeItem(LocalStorageKeyJson.OrganisationUnitId);
  };
}

export const organisationUnitsStore = new OrganisationUnitsStore();
