import React from 'react';
import Page from 'components/Page';
import { Redirect, Route, Switch } from 'react-router-dom';
import { sidebarPages } from 'constants/Routes';

const AuthedPage = React.memo(() => {
  return (
    <Page drawerOpen={true} hideMenus={false}>
      <Switch>
        {sidebarPages().map((page) => (
          <Route key={`route-${page.route}`} exact path={page.route} component={page.component} />
        ))}
        <Redirect to={sidebarPages()[0].route} />
      </Switch>
    </Page>
  );
});

export default AuthedPage;
