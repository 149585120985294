import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { usePlanningWeekStyles } from './PlanningWeek.style';
import { ReactComponent as FilterLine } from 'assets/icons/rework/filter-line.svg';
import { isMobile } from 'utils/isMobile';
import MaterialDatePickerButton from 'components/MaterialDatePicker/MaterialDatePickerButton';
import FullCalendar from 'components/FullCalendar';
import {
  executionWeekPlanningQuery,
  executionWeekPlanningService,
} from 'store/operations/executionWeekPlanning';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import FilterOperationsScreen from '../bottomModal/FilterOperations.screen';
import { ID } from '@datorama/akita';
import { createExecutionService } from 'store/operations/createExecution';
import { useTranslation } from 'react-i18next';
import { useFetchPlanningWeek } from 'store/operations/executionWeekPlanning/hooks/useFetchPlanningWeek';
import { addWeek, removeWeek } from 'utils/dates/format';
import { ReactComponent as CaretRightSVG } from 'assets/icons/caretRight.svg';
import { ReactComponent as CaretLeftSVG } from 'assets/icons/caretLeft.svg';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { DateClickArg } from '@fullcalendar/interaction';
import { useObservable } from '@ngneat/react-rxjs';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { sessionQuery } from 'store/session';
import { EventCalendar } from 'components/FullCalendar/TestData';
import PlanningWeekModalList from './modalList/PlanningWeekModalList';

interface PlanningWeekComponentProps {
  handleOpenModal: (executionId?: ID) => void;
}

const PlanningWeekComponent: FC<PlanningWeekComponentProps> = ({
  handleOpenModal,
}: PlanningWeekComponentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [wastes, setWastes] = useState<MaterialDropdownItem[]>([]);
  const [filteredWasteIds, setFilteredWasteIds] = useState<ID[]>([]);
  const [isFilterBottomMobalOpen, setIsFilterBottomMobalOpen] = useState(false);
  const [isPlanningListModalOpen, setIsPlanningListModalOpen] = useState<EventCalendar | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const classes = usePlanningWeekStyles();
  const isMobiles = isMobile();
  const { planning } = useFetchPlanningWeek(dateSelected);
  const [orgUnit] = useObservable(organisationUnitsQuery.activeOrgUnitId$);
  const [isPatrick] = useObservable(sessionQuery.isPatrick$);

  useEffect(() => {
    const _wastes = executionWeekPlanningQuery.wastes$.subscribe(setWastes);
    const _filteredWasteIds = executionWeekPlanningQuery.filteredWasteIds$.subscribe(
      setFilteredWasteIds
    );
    const _isLoading = executionWeekPlanningQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _wastes.unsubscribe();
      _filteredWasteIds.unsubscribe();
      _isLoading.unsubscribe();
    };
  }, []);

  const onDayClick = (info?: DateClickArg) => {
    if (!orgUnit || isPatrick) {
      return;
    }
    createExecutionService
      .openModal(undefined, { dateSelected: info?.date, hourSelected: info?.date })
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  const onEventSelected = (event: EventCalendar) => {
    if (event.extendedProps?.multiple === true) {
      setIsPlanningListModalOpen({
        ...event,
        start: event.extendedProps.realStart || new Date(),
        end: event.extendedProps.realEnd || new Date(),
      });
    } else {
      handleOpenModal(event.extendedProps?.id);
    }
  };

  const onPreviousClick = () => {
    setDateSelected(removeWeek(dateSelected));
  };

  const onNextClick = () => {
    setDateSelected(addWeek(dateSelected));
  };

  return (
    <>
      <Stack direction="column" alignItems="center" spacing={2}>
        <div className={classes.hideForMobile}>
          <Tooltip title={t('fe.operations.tooltips.previousWeek') ?? ''}>
            <IconButton onClick={() => onPreviousClick()}>
              <CaretLeftSVG />
            </IconButton>
          </Tooltip>
          <Button
            style={{ marginRight: 12 }}
            variant="basic"
            size="small"
            onClick={() => setDateSelected(new Date())}
          >
            {t('fe.misc.today')}
          </Button>
          <MaterialDatePickerButton
            value={dateSelected}
            handleChange={(value) => setDateSelected(value)}
          />
          <Tooltip title={t('fe.operations.tooltips.previousWeek') ?? ''}>
            <IconButton onClick={() => onNextClick()}>
              <CaretRightSVG />
            </IconButton>
          </Tooltip>
        </div>
      </Stack>
      {/*<Grid item className={classes.hideForMobile}>*/}
      {/*  <MaterialMultiDropdownDashboard*/}
      {/*    placeholder={t('fe.misc.filterWastes')}*/}
      {/*    required*/}
      {/*    // error="Field required"*/}
      {/*    color="secondary"*/}
      {/*    handleChange={executionWeekPlanningService.onSelectedWastes}*/}
      {/*    selectedValues={filteredWasteIds}*/}
      {/*    items={wastes}*/}
      {/*  />*/}
      {/*</Grid>*/}
      {isMobiles && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ flexWrap: 'nowrap' }}
          className={classes.gridContainer}
        >
          <Grid item style={{ display: 'flex', alignItems: 'center' }} container spacing={1}>
            <Grid item>
              <IconButton onClick={() => onPreviousClick()}>
                <CaretLeftSVG />
              </IconButton>
            </Grid>
            <Grid item>
              <Button variant="basic" size="small" onClick={() => setDateSelected(new Date())}>
                {t('fe.misc.today')}
              </Button>
            </Grid>
            <Grid item>
              <MaterialDatePickerButton
                value={dateSelected}
                handleChange={(value) => setDateSelected(value)}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => onNextClick()}>
                <CaretRightSVG />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.iconButtonDownload}
              onClick={() => setIsFilterBottomMobalOpen(true)}
            >
              <FilterLine />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card className={classes.cardCalendar}>
          <CardContent style={{ position: 'relative', cursor: isPatrick ? undefined : 'pointer' }}>
            <FullCalendar
              events={planning}
              goToDate={dateSelected ?? new Date()}
              onDateSelected={onDayClick}
              onEventSelected={onEventSelected}
            />
            {isLoading && (
              <CircularProgress
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      {isFilterBottomMobalOpen && isMobiles && (
        <FilterOperationsScreen
          title={t('fe.misc.filterWastes')}
          handleClickCloseModal={() => setIsFilterBottomMobalOpen(false)}
          handleChange={executionWeekPlanningService.onSelectedWastes}
          selectedValues={filteredWasteIds}
          dataFilter={wastes}
        />
      )}
      {isPlanningListModalOpen && (
        <PlanningWeekModalList
          handleClose={() => setIsPlanningListModalOpen(undefined)}
          from={isPlanningListModalOpen.start}
          to={isPlanningListModalOpen.end}
        />
      )}
    </>
  );
};

export default PlanningWeekComponent;
