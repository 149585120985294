import React, { FC, useEffect, useState } from 'react';
import AppBarDropdown from 'components/AppBar/AppBarDropdown/AppBarDropdown';
import { ReactComponent as IconLocalisation } from 'assets/icons/rework/icon_localisation.svg';
import { useSnackbar } from 'notistack';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { ID } from '@datorama/akita';
import {
  organisationUnitsQuery,
  organisationUnitsService,
} from 'store/organisation/organisationUnits';

const AppBarDropdownOrgUnit: FC = () => {
  const [searchCurrentValue, setSearchCurrentValue] = useState('');
  const [orgUnits, setOrgUnits] = useState<AutocompleteData[]>([]);
  const [orgUnitActiveId, setOrgUnitActiveId] = useState<ID>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const _orgUnitsAsAutocomplete = organisationUnitsQuery.orgUnitsAsAutocomplete$.subscribe(
      setOrgUnits
    );
    const _orgUnitActiveId = organisationUnitsQuery.activeOrgUnitId$.subscribe(setOrgUnitActiveId);

    return () => {
      _orgUnitsAsAutocomplete.unsubscribe();
      _orgUnitActiveId.unsubscribe();
    };
  }, [enqueueSnackbar]);

  return (
    <AppBarDropdown
      icon={<IconLocalisation />}
      label="Type de dechet 2"
      handleChange={(value) =>
        value
          ? organisationUnitsService.setOrgUnitAsActive(value)
          : organisationUnitsService.removeActiveOrgUnit()
      }
      // error={errors.currentEmail}
      value={orgUnits.find((it) => it.id === orgUnitActiveId)}
      data={organisationUnitsQuery.filterOrgUnits(orgUnits, searchCurrentValue)}
      placeholder="Select your organisation unit"
      handleSearchChange={setSearchCurrentValue}
    />
  );
};

export default AppBarDropdownOrgUnit;
