import { ID } from '@datorama/akita';
import { metawasteApi } from 'api/Axios';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';
import { FinanceScreenStatsStore, financeScreenStatsStore } from './finance-screen-stats.store';
import { TableFilter } from 'components/Select/SelectItem.interface';

export class FinanceScreenStatsService {
  constructor(private store: FinanceScreenStatsStore) {}

  calculatePercentageEvolution = (depart: number, end: number) => {
    let result = depart !== 0 ? ((end - depart) / depart) * 100 : end * 100;
    result = ~~result;
    return result;
  };

  private getExpenseValoEvolStats = async (
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    organisationId?: ID,
    orgUnitId?: ID
  ) => {
    const [expenseValoEvol, e] = await resolve(
      metawasteApi.getExpenseValoEvolution(from, to, dateFilterKind, orgUnitId, organisationId)
    );
    if (!expenseValoEvol || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueKind'));
    }
    this.store.updateExpenseValoEvolutionStats(expenseValoEvol);

    return expenseValoEvol;
  };

  private getServiceExpenseStats = async (
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    organisationId?: ID,
    orgUnitId?: ID
  ) => {
    const [serviceExpense, e] = await resolve(
      metawasteApi.getServiceExpense(from, to, dateFilterKind, orgUnitId, organisationId)
    );
    if (!serviceExpense || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueKind'));
    }
    this.store.updateServiceExpenseStats(serviceExpense);

    return serviceExpense;
  };

  private getWasteExpenseValoStats = async (
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    organisationId?: ID,
    orgUnitId?: ID
  ) => {
    const [wasteExpenseValo, e] = await resolve(
      metawasteApi.getWasteExpense(from, to, dateFilterKind, orgUnitId, organisationId)
    );
    if (!wasteExpenseValo || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueKind'));
    }
    this.store.updateWasteExpenseValoStats(wasteExpenseValo);

    return wasteExpenseValo;
  };

  fetchstats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    organisationId?: ID,
    orgUnitId?: ID,
    currentFilters?: TableFilter[]
  ) => {
    this.store.setExpenseValoEvolutionLoading(true);
    this.store.setServiceExpenseLoading(true);
    this.store.setWasteExpenseValoLoading(true);

    const ExpenseValoEvol = this.getExpenseValoEvolStats(
      from,
      to,
      dateFilterKind,
      organisationId,
      orgUnitId
    );
    const ServiceExpense = this.getServiceExpenseStats(
      from,
      to,
      dateFilterKind,
      organisationId,
      orgUnitId
    );
    const WasteExpenseValo = this.getWasteExpenseValoStats(
      from,
      to,
      dateFilterKind,
      organisationId,
      orgUnitId
    );

    await Promise.all([ExpenseValoEvol, ServiceExpense, WasteExpenseValo]);
  };
}

export const financeScreenStatsService = new FinanceScreenStatsService(financeScreenStatsStore);
