import { List, ListItemButton, ListItemText, Popover } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { useSearchDropdownBaseStyles } from './SearchDropdownBase.style';
import { Search } from '@mui/icons-material';
import { Colors } from 'style/materialStylings';
import MaterialTextfieldBase from 'components/MaterialTextfield/MaterialTextfieldBase';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { ID } from '@datorama/akita';
import { useTranslation } from 'react-i18next';

interface SearchDropdownBaseProps {
  // popupState: PopupState;
  handleChange?: (value?: ID) => void;
  handleSearchChange?: (value: string) => void;
  isLoadingSearch?: boolean;
  placeholderSearch?: string;
  handleClose?: () => void;
  data: AutocompleteData[];
  value?: AutocompleteData;
  bottomComponent?: React.ReactElement;
  anchorEl: HTMLDivElement | null;
}

const SearchDropdownBase: FC<SearchDropdownBaseProps> = (props: SearchDropdownBaseProps) => {
  const {
    // popupState,
    handleChange,
    handleSearchChange,
    isLoadingSearch,
    placeholderSearch,
    anchorEl,
    data,
    handleClose,
    value,
    bottomComponent,
  } = props;

  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const mainDiv = useRef(null);
  const classes = useSearchDropdownBaseStyles();

  const open = Boolean(anchorEl);

  return (
    <Popover
      id="popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => {
        handleClose?.();
      }}
      PaperProps={{
        style: { minWidth: anchorEl?.offsetWidth, maxHeight: '300px' },
      }}
    >
      <div>
        <MaterialTextfieldBase
          className={classes.searchField}
          type="search"
          autofocus
          startIcon={<Search htmlColor={Colors.darkGray} />}
          placeholder={placeholderSearch}
          color="secondary"
          value={searchValue}
          handleChange={(value) => {
            setSearchValue(value);
            handleSearchChange?.(value);
          }}
          isLoading={isLoadingSearch}
        />
        <List dense={true}>
          <ListItemButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleChange?.(undefined);
              setSearchValue('');
            }}
          >
            <ListItemText primary={t('fe.appBar.clientReset')} />
          </ListItemButton>
          {data.map((item) => (
            <ListItemButton
              key={item.id.toString()}
              onClick={(e) => {
                e.stopPropagation();
                handleChange?.(item.id);
                setSearchValue('');
              }}
            >
              <ListItemText primary={item.title} secondary={item.description} />
            </ListItemButton>
          ))}
        </List>
        {bottomComponent}
      </div>
    </Popover>
  );
};

export default SearchDropdownBase;
