import { useEffect, useRef } from 'react';
import { wastesDashboardQuery, wastesDashboardService } from 'store/dashboard/wastes/index';
import { combineLatest, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { useObservable } from '@ngneat/react-rxjs';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { organisationsQuery } from 'store/organisation/organisations';

export const useWasteDashboard = (from?: Date, to?: Date) => {
  const { enqueueSnackbar } = useSnackbar();
  const from$ = useRef(new Subject<Date>()).current;
  const to$ = useRef(new Subject<Date>()).current;
  const [wastes] = useObservable(wastesDashboardQuery.wastesDropdownItems$);

  useEffect(() => {
    const _queryParams = combineLatest([
      from$,
      to$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([from, to, organisationId, orgUnitId]) => {
        if (organisationId) {
          wastesDashboardService
            .fetchWastes(from, to, organisationId, orgUnitId)
            .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
        }
      });
    return () => {
      _queryParams.unsubscribe();
    };
  }, []);

  useEffect(() => from$.next(from), [from]);

  useEffect(() => to$.next(to), [to]);

  return { wastes };
};
