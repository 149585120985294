import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useDashboardMorganStyles } from './DashboardMorgan.style';
import BasicTab from 'components/Tabs/BasicTabs';
import MaterialDropdownDashboard from 'components/MaterialDropdown/MaterialDropdownDashboard';
import MaterialDatePickerButton from 'components/MaterialDatePicker/MaterialDatePickerButton';
import { ReactComponent as FilterLine } from 'assets/icons/rework/filter-line.svg';
import EventIcon from '@mui/icons-material/Event';
import FilterOperationsScreen from 'screens/auth/operations/bottomModal/FilterOperations.screen';
import { isMobile } from 'utils/isMobile';
import ActivitiesModalPanel from '../common/sidemodal/ActivitiesModal.panel';
import { ID } from '@datorama/akita';
import { createExecutionService } from 'store/operations/createExecution';
import DataCardsComponent from './components/DataCards/DataCards.component';
import { getEndDate, getStartDate } from 'utils/dates/parse';
import dayjs, { OpUnitType } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'utils/dates/format';
import CreateExecutionButtonComponent from './components/createExecutionButton/CreateExecutionButton.component';
import { useWasteDashboard } from 'store/dashboard/wastes/hooks/useWastesForDashboard';
import { Colors } from 'style/materialStylings';
import { DownloadButton } from './components/DownloadButton/downloadButton';
import { sessionQuery } from 'store/session';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import AddIcon from '@mui/icons-material/Add';
import ModalComponent from 'components/Modal/Modal';
import BookmarkCards from 'components/Bookmark/bookmarkSolocard/bookmarkCards';
import {
  bookmarkQuery,
  bookmarkService,
  BookmarkServiceDto,
  bookmarkStore,
} from 'store/operations/bookmark';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { useObservable } from '@ngneat/react-rxjs';
import { NoResultComponent } from '../common/components/NoResult';

dayjs.extend(LocalizedFormat);

interface DashboardMorganScreenProps {
  value?: Date;
  handleChange?: (date: Date) => void;
  dateCalendarSelected: Date;
  setDate: (newDate: Date) => void;
}

const DashboardMorganScreen: FC<DashboardMorganScreenProps> = (props) => {
  const { value, handleChange, setDate, dateCalendarSelected } = props;
  const [dropdownDashboard, setDropdownDashboard] = useState<OpUnitType>('month');
  const [multiDropdown, setMultiDropdown] = useState<string[]>([]);
  const [startDateButton, setStartDateButton] = useState<Date>(new Date());
  const [dateCalendar, setDateCalendar] = useState<Date>(new Date());
  const [isFilterBottomMobalOpen, setIsFilterBottomMobalOpen] = useState(false);
  const [isSideModalMobileOpen, setIsSideModalMobileOpen] = useState(false);
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
  const [organisationUnitID] = useObservable(organisationUnitsQuery.activeOrgUnitId$);
  const [bookmarks] = useObservable(bookmarkQuery.bookmarks$);
  const [isExternal] = useObservable(sessionQuery.isExternal$);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useDashboardMorganStyles();
  const isMobiles = isMobile();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<{ tabType: MetawasteTab; label: string }>({
    label: t('fe.dashboard.morgan.statisticButton'),
    tabType: MetawasteTab.STATS,
  });
  const startDate = useMemo(() => getStartDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);
  const endDate = useMemo(() => getEndDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);

  const getCurrentPeriod = (startDateButton: Date, dropdownDashboard: OpUnitType) => {
    const d = dayjs(startDateButton);

    const startDate = d.startOf(dropdownDashboard).format('LL');
    const endDate = d.endOf(dropdownDashboard).format('LL');

    return `${startDate} - ${endDate}`;
  };

  const { wastes } = useWasteDashboard(startDate, endDate);

  useEffect(() => {
    if (organisationUnitID && isBookmarkModalOpen) {
      bookmarkService
        .getBookmarks(organisationUnitID)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  }, [organisationUnitID, isBookmarkModalOpen]);

  const handleOpenModal = (
    executionId?: ID,
    dateSelected?: Date,
    bookmarkServices?: BookmarkServiceDto[]
  ) => {
    createExecutionService.openModal(
      executionId,
      isBefore(dateSelected) ? undefined : { dateSelected },
      bookmarkServices
    );

    bookmarkServices && bookmarkStore.isEditModalOpen(true);
  };

  const handleDeleteBookmark = (bookmarkId: number) => {
    bookmarkService
      .deleteBookmark(bookmarkId)
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  return (
    <Grid item xs={12} container spacing={2} className={classes.container}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.title}
      >
        <Grid item container justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant={isMobiles ? 'h3' : 'h1'}>Dashboard</Typography>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" spacing={2} xs={8}>
            {sessionQuery.isWorkSite() && (
              <Grid item>
                <Button
                  onClick={() => window.open('https://form.jotform.com/222431908487057')}
                  variant="outlined"
                  size="large"
                >
                  {t('fe.operations.button.worksite')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <CreateExecutionButtonComponent
                onClick={() => handleOpenModal(undefined, dateCalendarSelected, undefined)}
                styleButton={classes.createButtonContainer}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
                onClick={() => setIsBookmarkModalOpen(true)}
                disabled={!organisationUnitID}
              >
                <span>{t('fe.bookmarks.modal.button')}</span>
              </Button>
            </Grid>
            <Grid item className={classes.hideForDesktop}>
              <Button
                variant="contained"
                sx={{ backgroundColor: 'white' }}
                onClick={() => setIsSideModalMobileOpen(true)}
                startIcon={
                  <EventIcon
                    style={{
                      color: `${Colors.secondary}`,
                      marginRight: '0.5rem',
                    }}
                  />
                }
              >
                Voir le calendrier
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 7 }}>
        <BasicTab
          tabs={[{ label: t('fe.dashboard.morgan.statisticButton'), tabType: MetawasteTab.STATS }]}
          value={currentTab}
          handleChange={setCurrentTab}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent={isMobiles ? 'end' : 'space-between'}
        alignItems="center"
      >
        {isMobiles ? (
          <div style={{ display: 'flex' }}>
            <Grid container spacing={1}>
              <Grid item>
                <MaterialDatePickerButton
                  value={isMobiles && undefined}
                  handleChange={setStartDateButton}
                />
              </Grid>
              <Grid item>
                <IconButton
                  className={classes.iconButtonDownload}
                  onClick={() => setIsFilterBottomMobalOpen(true)}
                >
                  <FilterLine />
                </IconButton>
              </Grid>
              {!isExternal && (
                <Grid item>
                  <DownloadButton
                    startDate={startDate}
                    endDate={endDate}
                    multiDropdown={multiDropdown}
                    startDateButton={startDateButton}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant="basic"
                    size="small"
                    onClick={() => setStartDateButton(new Date())}
                  >
                    {t('fe.misc.today')}
                  </Button>
                </Grid>
                <Grid item>
                  <MaterialDropdownDashboard
                    required
                    sx={{ height: '28px !important' }}
                    color="secondary"
                    handleChange={(value) => setDropdownDashboard(value)}
                    value={dropdownDashboard}
                    items={[
                      { label: t('fe.misc.week'), value: 'week' },
                      { label: t('fe.misc.month'), value: 'month' },
                      { label: t('fe.misc.year'), value: 'year' },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <MaterialDatePickerButton
                    value={startDateButton ? new Date(startDateButton) : undefined}
                    handleChange={setStartDateButton}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid item>
              <Typography variant="h3">
                {getCurrentPeriod(startDateButton, dropdownDashboard)}
              </Typography>
            </Grid>
            {!isExternal && (
              <Grid item>
                <DownloadButton
                  startDate={startDate}
                  endDate={endDate}
                  multiDropdown={multiDropdown}
                  startDateButton={startDateButton}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <DataCardsComponent
        from={startDate}
        to={endDate}
        wasteIds={multiDropdown}
        dropwDownDashboard={dropdownDashboard}
      />
      {isFilterBottomMobalOpen && isMobiles && (
        <FilterOperationsScreen
          title={t('fe.misc.filterWastes')}
          handleClickCloseModal={() => setIsFilterBottomMobalOpen(false)}
          handleChange={(value) => setMultiDropdown(value)}
          selectedValues={multiDropdown}
          dataFilter={wastes}
        />
      )}
      {isSideModalMobileOpen && isMobiles && (
        <ActivitiesModalPanel
          buttonShow
          handleClickCloseSideBar={() => setIsSideModalMobileOpen(false)}
          dateCalendarSelected={dateCalendar}
          setDate={setDateCalendar}
        />
      )}
      {isBookmarkModalOpen && (
        <ModalComponent
          handleClose={() => setIsBookmarkModalOpen(false)}
          title={t('fe.bookmarks.modal.title')}
          maxWidth="lg"
          maxHeight="85vh"
        >
          <Box>
            <Grid container spacing={4}>
              {!bookmarks.length ? (
                <Stack flex={1} alignItems="center" height="400px" justifyContent="center">
                  <NoResultComponent />
                </Stack>
              ) : (
                bookmarks.map((it) => (
                  <Grid item xs={4} key={it.id}>
                    <BookmarkCards
                      bookmarkServices={it.bookmarkServices}
                      handleDeleteBookmark={() => handleDeleteBookmark(it.id)}
                      onOpenModal={(bookmarkServices: BookmarkServiceDto[]) =>
                        handleOpenModal(undefined, dateCalendarSelected, bookmarkServices)
                      }
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </ModalComponent>
      )}
    </Grid>
  );
};

export default DashboardMorganScreen;
