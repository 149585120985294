import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import dayjs from 'utils/data/dayjs.utils';
import SnackError from 'utils/models/snackError';
import {
  executionMonthPlanningQuery,
  executionMonthPlanningService,
} from 'store/dashboard/executionMonthPlanning';
import { createExecutionQuery } from 'store/operations/createExecution';
import { EventCalendar } from 'components/FullCalendar/TestData';

export const useFetchPlanningMonth = (monthSelected: Date) => {
  const { enqueueSnackbar } = useSnackbar();

  const monthSelected$ = useRef(new Subject<Date>()).current;

  const [planning, setPlanning] = useState<EventCalendar[]>([]);

  useEffect(() => {
    const _planningParams = combineLatest([
      monthSelected$,
      createExecutionQuery.needToReloadExecutions$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([monthSelected, _, activeOrgId, activeOrgUnitId]) => {
        fetchPlanning(monthSelected, activeOrgId, activeOrgUnitId);
      });

    const _planning$ = executionMonthPlanningQuery.planning$.subscribe(setPlanning);
    return () => {
      _planningParams.unsubscribe();
      _planning$.unsubscribe();
    };
  }, [monthSelected]);

  const fetchPlanning = (monthSelected?: Date, activeOrgId?: ID, activeOrgUnitId?: ID) => {
    if (monthSelected) {
      executionMonthPlanningService
        .fetchExecutionPlanning(
          dayjs(monthSelected).startOf('month').toISOString(),
          dayjs(monthSelected).endOf('month').toISOString(),
          activeOrgId,
          activeOrgUnitId
        )
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  };

  useEffect(() => {
    monthSelected$.next(monthSelected);
  }, [monthSelected]);

  return { planning };
};
