import { WasteDashboard } from 'store/dashboard/wastes/wastes-dashboard.model';
import { ID } from '@datorama/akita';

export interface WasteDashboardDto {
  wasteId: ID;
  wasteCount: number;
  wasteName: string;
}

export const wasteDtoToFormat = (dto: WasteDashboardDto): WasteDashboard => {
  return {
    id: dto.wasteId,
    wasteCount: dto.wasteCount,
    wasteName: dto.wasteName,
  };
};
