import headerSpacer, { ReactComponent as HeaderSpacerSVG } from 'assets/icons/spacer.svg';
import man, { ReactComponent as ManSVG } from 'assets/icons/man.svg';
import geo, { ReactComponent as GeoSVG } from 'assets/icons/geo.svg';
import settings, { ReactComponent as SettingsSVG } from 'assets/icons/settings.svg';
import bell, { ReactComponent as BellSVG } from 'assets/icons/bell.svg';
import back, { ReactComponent as BackSVG } from 'assets/icons/back.svg';
import bars, { ReactComponent as BarsSVG } from 'assets/icons/bars.svg';
import emptyState, { ReactComponent as EmptyStateSVG } from 'assets/icons/emptystate.svg';
import dashboard, { ReactComponent as DashboardSVG } from 'assets/icons/dashboard.svg';
import pickups from 'assets/icons/pickups.svg';
import star, { ReactComponent as StarSVG } from 'assets/icons/star.svg';
import documents, { ReactComponent as DocumentsSVG } from 'assets/icons/documents.svg';
import issues, { ReactComponent as IssuesSVG } from 'assets/icons/issues.svg';
import hide, { ReactComponent as HideSVG } from 'assets/icons/hide.svg';
import close, { ReactComponent as CloseSVG } from 'assets/icons/close.svg';
import print, { ReactComponent as PrintSVG } from 'assets/icons/print.svg';
import copy, { ReactComponent as CopySVG } from 'assets/icons/copy.svg';
import chevronUp, { ReactComponent as ChevronUpSVG } from 'assets/icons/chevronUp.svg';
import chevronDown, { ReactComponent as ChevronDownSVG } from 'assets/icons/chevronDown.svg';
import arrowDown, { ReactComponent as ArrowSVG } from 'assets/icons/arrowDown.svg';
import dropDownIcon, { ReactComponent as DropdownSVG } from 'assets/icons/dropdown.svg';
import water, { ReactComponent as WaterSVG } from 'assets/icons/water.svg';
import solidImg, { ReactComponent as SolidSVG } from 'assets/icons/solid.svg';
import foodGarbageImg, { ReactComponent as FoodGarbageSVG } from 'assets/icons/apple.svg';
import liquidImg, { ReactComponent as LiquidSVG } from 'assets/icons/drop.svg';
import BigArrowRight, { ReactComponent as BigArrowRightSVG } from 'assets/icons/bigArrowRight.svg';
import pickupsIcon, { ReactComponent as PickupsIconSVG } from 'assets/icons/pickupIcon.svg';
import x, { ReactComponent as XSVG } from 'assets/icons/x.svg';
import searchIcon, { ReactComponent as SearchSVG } from 'assets/icons/search.svg';
import memberIcon, { ReactComponent as MemberIconSVG } from 'assets/icons/memberIcon.svg';
import cloudIcon, { ReactComponent as CloudIconSVG } from 'assets/icons/cloudIcon.svg';
import closeCircle, { ReactComponent as CloseCircleSVG } from 'assets/icons/closeCircle.svg';
import { ReactComponent as TableSortAsc } from 'assets/icons/tableDropdown.svg';
import { ReactComponent as TableSortDesc } from 'assets/icons/tableDropup.svg';
import caretRight, { ReactComponent as CaretRightSVG } from 'assets/icons/caretRight.svg';
import caretLeft, { ReactComponent as CaretLeftSVG } from 'assets/icons/caretLeft.svg';
import emptySelect, { ReactComponent as EmptySelectSVG } from 'assets/icons/emptySelect.svg';
import exclamation, { ReactComponent as ExclamationSVG } from 'assets/icons/exclamation.svg';
import trash, { ReactComponent as TrashSVG } from 'assets/icons/trash.svg';
import truck, { ReactComponent as TruckSVG } from 'assets/icons/truck.svg';
import issue, { ReactComponent as IssueSVG } from 'assets/icons/issue.svg';
import tripleDots, { ReactComponent as TripleDotsSVG } from 'assets/icons/tripleDots.svg';
import plus, { ReactComponent as PlusSVG } from 'assets/icons/plus.svg';
import done, { ReactComponent as DoneSVG } from 'assets/icons/done.svg';
import sort, { ReactComponent as SortSVG } from 'assets/icons/sort.svg';
import check, { ReactComponent as CheckSVG } from 'assets/icons/check.svg';
import blocker, { ReactComponent as BlockerSVG } from 'assets/icons/blocker.svg';
import cancel, { ReactComponent as CancelSVG } from 'assets/icons/cancel.svg';
import question, { ReactComponent as QuestionSVG } from 'assets/icons/Question.svg';
import questionSquare, {
  ReactComponent as QuestionSquareSVG,
} from 'assets/icons/questionSquare.svg';
import playCircle, { ReactComponent as PlayCircleSVG } from 'assets/icons/playCircle.svg';
import operator, { ReactComponent as OperatorSVG } from 'assets/icons/operator.svg';
import absentContainer, {
  ReactComponent as AbsentContainerSVG,
} from 'assets/icons/absentcontainer.svg';
import brokenContainer, {
  ReactComponent as BrokenContainerSVG,
} from 'assets/icons/brokencontainer.svg';
import deprecatedContainer, {
  ReactComponent as DeprecatedContainerSVG,
} from 'assets/icons/deprecatedcontainer.svg';
import noAccess, { ReactComponent as NoAccessSVG } from 'assets/icons/noaccess.svg';
import wrongTypeWaste, {
  ReactComponent as WrongTypeWasteSVG,
} from 'assets/icons/wrongtypewaste.svg';
import offline, { ReactComponent as OfflineSVG } from 'assets/icons/offline.svg';
import sliderNext, { ReactComponent as SliderNextSVG } from 'assets/icons/sliderNext.svg';
import arrowBack, { ReactComponent as ArrowBackSVG } from 'assets/icons/arrowBack.svg';
import download, { ReactComponent as DownloadSVG } from 'assets/icons/download.svg';
import tableView, { ReactComponent as TableViewSVG } from 'assets/icons/tableView.svg';

enum IconNames {
  Sort = 'sort',
  SortSvg = 'sortSvg',
  Trash = 'trash',
  TrashSvg = 'trashSvg',
  Truck = 'truck',
  TruckSvg = 'truckSvg',
  CaretLeft = 'caretLeft',
  CaretLeftSvg = 'caretLeftSvg',
  CaretRight = 'caretRight',
  CaretRightSvg = 'caretRightSvg',
  HeaderSpacer = 'spacer',
  HeaderSpacerSvg = 'headerSpacerSvg',
  Man = 'man',
  ManSvg = 'manSvg',
  Geo = 'geo',
  GeoSvg = 'geoSvg',
  Settings = 'settings',
  SettingsSvg = 'settingsSvg',
  Search = 'search',
  SearchSvg = 'searchSvg',
  Bell = 'bell',
  BellSvg = 'bellSvg',
  BackIcon = 'back',
  BackIconSvg = 'backIconSvg',
  Bars = 'bars',
  BarsSvg = 'barsSvg',
  EmptyStateIcon = 'emptyState',
  EmptyStateIconSvg = 'emptyStateSvg',
  DashboardIcon = 'dashboard',
  DashboardIconSvg = 'dashboardSvg',
  PickupsIconSvg = 'PickupSvg',
  PickupsIcon = 'pickup',
  StarIcon = 'star',
  StarIconSvg = 'starIconSvg',
  DocumentsIcon = 'documents',
  DocumentsIconSvg = 'documentsIconSvg',
  IssuesIcon = 'issues',
  IssuesIconSvg = 'issuesIconSvg',
  Hide = 'hide',
  HideSvg = 'hideSvg',
  Close = 'close',
  CloseSvg = 'closeSvg',
  Print = 'print',
  PrintSvg = 'printSvg',
  Copy = 'copy',
  CopySvg = 'copySvg',
  ChevronUp = 'chevronUp',
  ChevronUpSvg = 'chevronUpSvg',
  ChevronDown = 'chevronDown',
  ChevronDownSvg = 'chevronDownSvg',
  ArrowDown = 'arrowDown',
  ArrowDownSvg = 'arrowDownSvg',
  Water = 'water',
  WaterSvg = 'waterSvg',
  Garbage = 'garbage',
  GarbageSvg = 'garbageSvg',
  FoodGarbage = 'food',
  FoodGarbageSvg = 'foodGarbageSvg',
  Liquid = 'liquid',
  LiquidSvg = 'liquidSvg',
  Dropdown = 'dropdown',
  DropdownSvg = 'dropdownSvg',
  DropdownComponent = 'Dropdown',
  TableSortDesc = 'tableSortDesc',
  TableSortAsc = 'tableSortAsc',
  MemberIcon = 'memberIcon',
  MemberIconSvg = 'memberIconSvg',
  CloudIcon = 'cloudIcon',
  CloudIconSvg = 'cloudIconSvg',
  CloseCircle = 'closeCircle',
  CloseCircleSvg = 'closeCircleSvg',
  PickupsBtnIcon = 'pickupsIcon',
  PickupsBtnIconSvg = 'pickupsBtnIconSvg',
  X = 'x',
  XSvg = 'xSvg',
  EmptySelectIcon = 'emptySelect',
  EmptySelectIconSvg = 'emptySelectIconSvg',
  Exclamation = 'exclamation',
  ExclamationSvg = 'ExclamationSvg',
  Issue = 'issue',
  IssueSvg = 'issueSvg',
  BigArrowRight = 'BigArrowRight',
  BigArrowRightSvg = 'bigArrowRightSvg',
  TripleDots = 'tripleDots',
  TripleDotsSvg = 'tripleDotsSvg',
  Plus = 'plus',
  PlusSvg = 'plusSvg',
  Done = 'done',
  DoneSvg = 'doneSvg',
  Check = 'check',
  CheckSvg = 'checkSvg',
  Blocker = 'blocker',
  BlockerSvg = 'blockerSvg',
  Cancel = 'cancel',
  CancelSvg = 'cancelSvg',
  AbsentContainer = 'absentContainer',
  AbsentContainerSvg = 'absentContainerSvg',
  BrokenContainer = 'brokenContainer',
  BrokenContainerSvg = 'brokenContainerSvg',
  DeprecatedContainer = 'deprecatedContainer',
  DeprecatedContainerSvg = 'deprecatedContainerSvg',
  NoAccess = 'noAccess',
  NoAccessSvg = 'noAccessSvg',
  WrongTypeWaste = 'wrongTypeWaste',
  WrongTypeWasteSvg = 'wrongTypeWasteSvg',
  Question = 'question',
  QuestionSvg = 'questionSvg',
  QuestionSquare = 'questionSquare',
  QuestionSquareSvg = 'questionSquareSvg',
  PlayCircle = 'playCircle',
  PlayCircleSvg = 'playCircleSvg',
  Operator = 'operator',
  OperatorSvg = 'operatorSvg',
  Offline = 'offline',
  OfflineSvg = 'offlineSvg',
  SliderNext = 'sliderNext',
  SliderNextSvg = 'sliderNextSvg',
  ArrowBack = 'arrowBack',
  ArrowBackSvg = 'arrowBackSvg',
  Download = 'download',
  DownloadSvg = 'downloadSvg',
  TableView = 'tableView',
  TableViewSvg = 'tableViewSvg',
}

export const iconSrc = {
  [IconNames.Sort]: sort,
  [IconNames.SortSvg]: SortSVG,
  [IconNames.Trash]: trash,
  [IconNames.TrashSvg]: TrashSVG,
  [IconNames.Truck]: truck,
  [IconNames.TruckSvg]: TruckSVG,
  [IconNames.HeaderSpacer]: headerSpacer,
  [IconNames.HeaderSpacerSvg]: HeaderSpacerSVG,
  [IconNames.Man]: man,
  [IconNames.ManSvg]: ManSVG,
  [IconNames.Geo]: geo,
  [IconNames.GeoSvg]: GeoSVG,
  [IconNames.Settings]: settings,
  [IconNames.SettingsSvg]: SettingsSVG,
  [IconNames.Bell]: bell,
  [IconNames.BellSvg]: BellSVG,
  [IconNames.BackIcon]: back,
  [IconNames.BackIconSvg]: BackSVG,
  [IconNames.Bars]: bars,
  [IconNames.BarsSvg]: BarsSVG,
  [IconNames.EmptyStateIcon]: emptyState,
  [IconNames.EmptyStateIconSvg]: EmptyStateSVG,
  [IconNames.DashboardIcon]: dashboard,
  [IconNames.DashboardIconSvg]: DashboardSVG,
  [IconNames.PickupsIcon]: pickups,
  [IconNames.PickupsIconSvg]: PickupsIconSVG,
  [IconNames.StarIcon]: star,
  [IconNames.StarIconSvg]: StarSVG,
  [IconNames.DocumentsIcon]: documents,
  [IconNames.DocumentsIconSvg]: DocumentsSVG,
  [IconNames.IssuesIcon]: issues,
  [IconNames.IssuesIconSvg]: IssuesSVG,
  [IconNames.Copy]: copy,
  [IconNames.CopySvg]: CopySVG,
  [IconNames.Hide]: hide,
  [IconNames.HideSvg]: HideSVG,
  [IconNames.Close]: close,
  [IconNames.CloseSvg]: CloseSVG,
  [IconNames.Print]: print,
  [IconNames.PrintSvg]: PrintSVG,
  [IconNames.ChevronUp]: chevronUp,
  [IconNames.ChevronUpSvg]: ChevronUpSVG,
  [IconNames.ChevronDown]: chevronDown,
  [IconNames.ChevronDownSvg]: ChevronDownSVG,
  [IconNames.ArrowDown]: arrowDown,
  [IconNames.ArrowDownSvg]: ArrowSVG,
  [IconNames.Water]: water,
  [IconNames.WaterSvg]: WaterSVG,
  [IconNames.Garbage]: solidImg,
  [IconNames.GarbageSvg]: SolidSVG,
  [IconNames.FoodGarbage]: foodGarbageImg,
  [IconNames.FoodGarbageSvg]: FoodGarbageSVG,
  [IconNames.Liquid]: liquidImg,
  [IconNames.LiquidSvg]: LiquidSVG,
  [IconNames.Dropdown]: dropDownIcon,
  [IconNames.DropdownSvg]: DropdownSVG,
  [IconNames.TableSortDesc]: TableSortDesc,
  [IconNames.TableSortAsc]: TableSortAsc,
  [IconNames.DropdownComponent]: DropdownSVG,
  [IconNames.Search]: searchIcon,
  [IconNames.SearchSvg]: SearchSVG,
  [IconNames.MemberIcon]: memberIcon,
  [IconNames.MemberIconSvg]: MemberIconSVG,
  [IconNames.CloudIcon]: cloudIcon,
  [IconNames.CloudIconSvg]: CloudIconSVG,
  [IconNames.CloseCircle]: closeCircle,
  [IconNames.CloseCircleSvg]: CloseCircleSVG,
  [IconNames.PickupsBtnIcon]: pickupsIcon,
  [IconNames.PickupsBtnIconSvg]: PickupsIconSVG,
  [IconNames.X]: x,
  [IconNames.XSvg]: XSVG,
  [IconNames.CaretLeft]: caretLeft,
  [IconNames.CaretLeftSvg]: CaretLeftSVG,
  [IconNames.CaretRight]: caretRight,
  [IconNames.CaretRightSvg]: CaretRightSVG,
  [IconNames.EmptySelectIcon]: emptySelect,
  [IconNames.EmptySelectIconSvg]: EmptySelectSVG,
  [IconNames.Exclamation]: exclamation,
  [IconNames.ExclamationSvg]: ExclamationSVG,
  [IconNames.BigArrowRight]: BigArrowRight,
  [IconNames.BigArrowRightSvg]: BigArrowRightSVG,
  [IconNames.TripleDots]: tripleDots,
  [IconNames.TripleDotsSvg]: TripleDotsSVG,
  [IconNames.Issue]: issue,
  [IconNames.IssueSvg]: IssueSVG,
  [IconNames.Plus]: plus,
  [IconNames.PlusSvg]: PlusSVG,
  [IconNames.Done]: done,
  [IconNames.DoneSvg]: DoneSVG,
  [IconNames.Check]: check,
  [IconNames.CheckSvg]: CheckSVG,
  [IconNames.Blocker]: blocker,
  [IconNames.BlockerSvg]: BlockerSVG,
  [IconNames.Cancel]: cancel,
  [IconNames.CancelSvg]: CancelSVG,
  [IconNames.AbsentContainer]: absentContainer,
  [IconNames.AbsentContainerSvg]: AbsentContainerSVG,
  [IconNames.BrokenContainer]: brokenContainer,
  [IconNames.BrokenContainerSvg]: BrokenContainerSVG,
  [IconNames.DeprecatedContainer]: deprecatedContainer,
  [IconNames.DeprecatedContainerSvg]: DeprecatedContainerSVG,
  [IconNames.NoAccess]: noAccess,
  [IconNames.NoAccessSvg]: NoAccessSVG,
  [IconNames.WrongTypeWaste]: wrongTypeWaste,
  [IconNames.WrongTypeWasteSvg]: WrongTypeWasteSVG,
  [IconNames.Question]: question,
  [IconNames.QuestionSvg]: QuestionSVG,
  [IconNames.QuestionSquare]: questionSquare,
  [IconNames.QuestionSquareSvg]: QuestionSquareSVG,
  [IconNames.PlayCircle]: playCircle,
  [IconNames.PlayCircleSvg]: PlayCircleSVG,
  [IconNames.Operator]: operator,
  [IconNames.OperatorSvg]: OperatorSVG,
  [IconNames.Offline]: offline,
  [IconNames.OfflineSvg]: OfflineSVG,
  [IconNames.SliderNext]: sliderNext,
  [IconNames.SliderNextSvg]: SliderNextSVG,
  [IconNames.ArrowBack]: arrowBack,
  [IconNames.ArrowBackSvg]: ArrowBackSVG,
  [IconNames.Download]: download,
  [IconNames.DownloadSvg]: DownloadSVG,
  [IconNames.TableView]: tableView,
  [IconNames.TableViewSvg]: TableViewSVG,
};
