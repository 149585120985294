import { useEffect, useRef, useState } from 'react';
import { combineLatest, from, Observable, of, Subject, timer } from 'rxjs';
import { EventCalendar } from 'components/FullCalendar/TestData';
import { createExecutionQuery } from 'store/operations/createExecution';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce, map, switchMap } from 'rxjs/operators';
import { executionMonthPlanningService } from '../execution-month-planning.service';
import { useSnackbar } from 'notistack';
import {
  ExecutionBetweenDatesDto,
  formatToEventCalendar,
} from 'api/dto/execution/ExecutionBetweenDatesDto';

export const useFetchPlanningDay = (daySelected: Date) => {
  const { enqueueSnackbar } = useSnackbar();
  const daySelected$ = useRef(new Subject<Date>()).current;
  const [executions, setExecutions] = useState<EventCalendar[]>([]);

  function fetchExecutionsForDay(
    daySelected: Date,
    activeOrgId: number | string | undefined,
    activeOrgUnitId: number | string | undefined
  ): Observable<ExecutionBetweenDatesDto[] | undefined> {
    try {
      if (daySelected) {
        return from(
          executionMonthPlanningService.fetchExecutionForDay(
            daySelected,
            activeOrgId,
            activeOrgUnitId
          )
        );
      }
    } catch (e) {
      return of([]);
    }
    return of([]);
  }

  useEffect(() => {
    const _params = combineLatest([
      daySelected$,
      createExecutionQuery.needToReloadExecutions$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
    ])
      .pipe(
        debounce(() => timer(500)),
        switchMap(([daySelected, _, activeOrgId, activeOrgUnitId]) =>
          fetchExecutionsForDay(daySelected, activeOrgId, activeOrgUnitId)
        ),
        map((executions: ExecutionBetweenDatesDto[] | undefined) =>
          executions?.map(formatToEventCalendar)
        )
      )
      .subscribe((executions) => {
        setExecutions(executions || []);
      });

    return () => {
      _params.unsubscribe();
    };
  }, [daySelected]);

  useEffect(() => {
    daySelected$.next(daySelected);
  }, [daySelected]);

  return { executions };
};
