import React, { FC, useEffect, useState } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { isMobile } from 'utils/isMobile';
import { useTranslation } from 'react-i18next';
import { OpUnitType } from 'dayjs';
import { getPeriodLabels } from 'utils/data/barchart.utils';
import { EcologyStats } from 'store/dashboard/ecologyScreenStats/ecology-screen-stats.model';
import { Colors } from 'constants/Colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface EcologicEvolutionProps {
  evolutionStats: EcologyStats[];
  unit?: string;
  dropDownDashboard: OpUnitType;
  currentDate: Date;
}

const EcologicEvolution: FC<EcologicEvolutionProps> = (props) => {
  const { dropDownDashboard, evolutionStats, currentDate } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();

  const [labels, setLabels] = useState(getPeriodLabels(dropDownDashboard, currentDate));
  useEffect(() => {
    setLabels(getPeriodLabels(dropDownDashboard, currentDate));
  }, [currentDate, dropDownDashboard]);
  const getMaxValue = () => {
    return Math.max(
      ...evolutionStats.map((data) => Math.max(data.processingCo2, data.processingBurnCo2))
    );
  };

  return (
    <div style={{ height: '100%', width: '100% !important', margin: '30px 0px' }}>
      <Line
        height="100%"
        typeof="line"
        options={{
          elements: {
            point: {
              radius: 0,
              hoverRadius: 5,
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            yAxis: {
              min: 0,
              max: getMaxValue(),
              beginAtZero: true,
              ticks: {
                color: 'black',
              },
              stacked: false,
            },
            xAxis: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 10,
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            filler: {
              propagate: true,
            },
            tooltip: {
              // callbacks: {
              //   beforeFooter: function (context) {
              //     return context.toString() + 'CHF';
              //   },
              // },
              padding: 50,
              displayColors: false,
              backgroundColor: 'transparent',
              bodyColor: 'black',
              mode: 'nearest',
              bodyAlign: 'center',
              titleColor: 'transparent',
              bodyFont: {
                size: 14,
                weight: 'bold',
                lineHeight: 20,
              },
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            // {
            //   label: t('fe.dashboard.morgan.stats.ecologic.evolution.before'),
            //   data: dataBefore,
            //   borderColor: '#848F9D',
            //   borderDash: [7, 7, 7, 7, 7, 7, 7],
            //   borderWidth: 2,
            //   tension: 0.4,
            //   fill: false,
            // },
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.burnCo2'),
              data: evolutionStats.map((data) => data.processingBurnCo2),
              borderColor: Colors.warning,
              // backgroundColor: gradienta,
              // backgroundColor: createGradient(chart.ctx, chart.chartArea),
              // backgroundColor: '#19CC3D',
              borderWidth: 2,
              tension: 0.4,
              fill: '-1',
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.pickupCo2'),
              data: evolutionStats.map((data) => data.pickupCo2),
              borderColor: Colors.primary,
              // backgroundColor: gradienta,
              // backgroundColor: createGradient(chart.ctx, chart.chartArea),
              // backgroundColor: '#19CC3D',
              borderWidth: 2,
              tension: 0.4,
              fill: '-1',
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.processingCo2'),
              data: evolutionStats.map((data) => data.processingCo2),
              borderColor: Colors.blue,
              // backgroundColor: gradienta,
              // backgroundColor: createGradient(chart.ctx, chart.chartArea),
              // backgroundColor: '#19CC3D',
              borderWidth: 2,
              tension: 0.4,
              fill: '-1',
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
          ],
        }}
      />
    </div>
  );
};

export default EcologicEvolution;
