import { CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultComponent } from './NoResult';
import CardOperations from 'components/Card/CardOperations/CardOperations';
import {
  formatWeight,
  formatWeightUnit,
} from 'store/dashboard/operationScreenStats/utils/formatWeight';

interface OperationAverageQuantityComponentProps {
  average: number;
  total: number;
  isLoading: boolean;
}

export const OperationAverageQuantityComponent = (
  props: OperationAverageQuantityComponentProps
) => {
  const { isLoading, average, total } = props;
  const { t } = useTranslation();
  if (isLoading) {
    return <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />;
  }
  if (!total || ~~total === 0) {
    return <NoResultComponent />;
  }
  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={2} style={{ paddingTop: 20 }}>
      <Grid item xs={6}>
        <CardOperations
          data={formatWeight(total)}
          percentage={formatWeightUnit(total)}
          percentageSubTitle={t('fe.dashboard.morgan.stats.operations.tonnage.totalSubtitle')}
          background="secondary.light"
          description={t('fe.dashboard.morgan.stats.operations.tonnage.totalDescription')}
        />
      </Grid>
      <Grid item xs={6}>
        <CardOperations
          data={formatWeight(average)}
          percentage={formatWeightUnit(average)}
          percentageSubTitle={t('fe.dashboard.morgan.stats.operations.tonnage.averageSubtitle')}
          background="secondary.light"
          description={t('fe.dashboard.morgan.stats.operations.tonnage.averageDescription')}
        />
      </Grid>
    </Grid>
  );
  // return <RoundSingle numberTotalOfContainer={~~average} numberOfContainerUsed={1000} />;
};
