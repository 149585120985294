import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { createExecutionQuery } from 'store/operations/createExecution';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { executionWeekPlanningService } from '../execution-week-planning.service';
import dayjs from 'utils/data/dayjs.utils';
import SnackError from 'utils/models/snackError';
import { executionWeekPlanningQuery } from '../execution-week-planning.query';
import { useObservable } from '@ngneat/react-rxjs';
import { isMobile } from 'utils/isMobile';
import { filtersQuery } from 'store/common/filters/filters.query';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';

export const useFetchPlanningWeek = (dateSelected: Date) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = isMobile();

  const dateSelected$ = useRef(new Subject<Date>()).current;
  const [planning] = useObservable(executionWeekPlanningQuery.planning$(isMobiles));

  useEffect(() => {
    const _planningParams = combineLatest([
      dateSelected$,
      createExecutionQuery.needToReloadExecutions$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
      filtersQuery.currentFilters$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([dateSelected, _, activeOrgId, activeOrgUnitId, currentFilters]) => {
        fetchPlanning(dateSelected, activeOrgId, activeOrgUnitId, currentFilters);
      });

    return () => {
      _planningParams.unsubscribe();
    };
  }, []);

  const fetchPlanning = (
    dateSelected?: Date,
    activeOrgId?: ID,
    activeOrgUnitId?: ID,
    currentFilters?: TableFilter[]
  ) => {
    if (dateSelected) {
      executionWeekPlanningService
        .fetchExecutionPlanning(
          dayjs(dateSelected).startOf('week').toISOString(),
          dayjs(dateSelected).endOf('week').toISOString(),
          activeOrgId,
          activeOrgUnitId,
          toRequestFilters(currentFilters || [])
        )
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  };

  useEffect(() => {
    dateSelected$.next(dateSelected);
  }, [dateSelected]);

  return { planning };
};
