import { organisationUnitsStore, OrganisationUnitsStore } from './organisation-units.store';
import { OrganisationUnit } from './organisation-unit.model';
import { ID } from '@datorama/akita';

export class OrganisationUnitsService {
  constructor(private store: OrganisationUnitsStore) {}

  populateDataFromOrganisation = (organisationUnits: OrganisationUnit[]) => {
    this.store.set(organisationUnits);
    if (organisationUnits.length === 1) {
      this.store.setActiveOrgUnitId(organisationUnits[0].id);
    } else {
      this.store.setActiveOrgUnitId(undefined);
    }
  };

  setOrgUnitAsActive = (orgUnitId: ID) => {
    this.store.setActiveOrgUnitId(orgUnitId);
  };

  removeActiveOrgUnit = () => {
    this.store.removeActiveOrgUnit();
  };
}

export const organisationUnitsService = new OrganisationUnitsService(organisationUnitsStore);
