import { InputLabel } from '@mui/material';
import React, { FC } from 'react';
import style from './MaterialBasicTextfield.style';
import { WithStyles, withStyles } from '@mui/styles';
import MaterialTextfieldBase, { MaterialTextfieldBaseProps } from '../MaterialTextfieldBase';

interface MaterialTextfieldProps extends WithStyles<typeof style>, MaterialTextfieldBaseProps {}

const MaterialBasicTextfield: FC<MaterialTextfieldProps> = (props: MaterialTextfieldProps) => {
  const { classes, error, label, required } = props;

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <MaterialTextfieldBase {...props} className={classes.textfield} />
    </div>
  );
};

export default withStyles(style)(MaterialBasicTextfield);
