import { InputLabel } from '@mui/material';
import React, { FC } from 'react';
import style from './MaterialTimeMultipleTextfield.style';
import { WithStyles, withStyles } from '@mui/styles';
import MaterialTimeTextfieldBase from '../MaterialTimeTextfieldBase';

interface MaterialTextfieldProps extends WithStyles<typeof style> {
  label?: string;
  labelFrom?: string;
  labelTo?: string;
  name?: string;
  required?: boolean;
  readOnly?: boolean;
  valueFrom?: Date | null;
  handleChangeFrom?: (value: Date | null) => void;
  valueTo?: Date | null;
  handleChangeTo?: (value: Date | null) => void;
  error?: string;
  color?: 'primary' | 'secondary';
  customStyle?: React.CSSProperties;
}

const MaterialTimeMultipleTextfield: FC<MaterialTextfieldProps> = (
  props: MaterialTextfieldProps
) => {
  const {
    classes,
    error,
    label,
    labelFrom,
    labelTo,
    valueFrom,
    valueTo,
    handleChangeFrom,
    handleChangeTo,
    required,
    color,
    readOnly,
    customStyle,
  } = props;

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <div className={classes.textfields}>
        <InputLabel>{labelFrom}</InputLabel>
        <MaterialTimeTextfieldBase
          customStyle={customStyle}
          value={valueFrom}
          color={color}
          handleChange={handleChangeFrom}
          readOnly={readOnly}
        />
        <InputLabel>{labelTo}</InputLabel>
        <MaterialTimeTextfieldBase
          customStyle={customStyle}
          value={valueTo}
          color={color}
          handleChange={handleChangeTo}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default withStyles(style)(MaterialTimeMultipleTextfield);
