import React, { FC, useEffect, useState } from 'react';
import {
  createExecutionQuery,
  createExecutionService,
  createExecutionStore,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import MaterialDropdownForm from 'components/MaterialDropdown/MaterialDropdownForm';
import { Alert, Button, Grid } from '@mui/material';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield/MaterialBasicTextfield';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { useSetServiceId } from 'store/operations/createExecution/hooks/useSetServiceId';
import { formatTranslatedName } from 'utils/data/i18n.utils';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import SelectServiceModal from 'screens/auth/modals/executionModal/SelectService.modal';
import { sessionQuery } from 'store/session';

interface ExecutionServiceFieldProps {
  execution: ExecutionDetailsUi;
  updateExecutionField: (name: string) => (value: any) => void;
}

const ExecutionServiceField: FC<ExecutionServiceFieldProps> = (props) => {
  const { execution, updateExecutionField } = props;

  const [allServices] = useObservable(createExecutionQuery.allServices$(execution.id));
  const [wastes] = useObservable(createExecutionQuery.wastes$(execution.id));
  const [containers] = useObservable(createExecutionQuery.containers$(execution.id));
  const [services] = useObservable(createExecutionQuery.services$(execution.id));
  const [canEditService] = useObservable(createExecutionQuery.canEditService$(execution.id));
  const [isFirstExecLocked] = useObservable(createExecutionQuery.isFirstExecLocked$(execution.id));
  const [matchingServices] = useObservable(createExecutionQuery.matchingServices$);
  const [haulingDestinationLoading] = useObservable(
    createExecutionQuery.haulingDestinationLoading$
  );
  const [isMorgan] = useObservable(sessionQuery.isMorgan$);
  const [isPatrick] = useObservable(sessionQuery.isPatrick$);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [showSelectService, setShowSelectService] = useState(false);

  useSetServiceId(execution);

  useEffect(() => {
    if (
      isMorgan &&
      !execution?.parentExecutionId &&
      execution?.serviceKindId &&
      !execution?.dto?.status
    ) {
      createExecutionService
        .fetchExecutionsServices(
          isMorgan
            ? organisationUnitsQuery.activeOrgUnitId ?? ''
            : createExecutionStore.getSelectedClientUnit() ?? '',
          execution.serviceKindId
        )
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  }, [execution?.serviceKindId, execution?.parentExecutionId]);

  useEffect(() => {
    if (wastes?.length === 1 && !execution.wasteId) {
      updateExecutionField('wasteId')(wastes[0].value);
    }
  }, [wastes, updateExecutionField, execution.wasteId]);

  useEffect(() => {
    if (containers?.length === 1 && !execution.containerId) {
      updateExecutionField('containerId')(containers[0].value);
    }
  }, [containers, updateExecutionField, execution.containerId]);

  return (
    <>
      <Grid item xs={12}>
        {wastes?.length && canEditService ? (
          <MaterialDropdownForm
            label={t('fe.executionModal.executionInfosForm.serviceTrashLabel')}
            color="secondary"
            placeholder={t('fe.executionModal.executionInfosForm.serviceTrashPlaceholder')}
            disabled={isFirstExecLocked}
            enableSearch={true}
            handleChange={(value) => {
              if (!value) {
                createExecutionService.setExecutionDetail({
                  ...execution,
                  wasteId: undefined,
                  containerId: undefined,
                  serviceId: undefined,
                });
                createExecutionService.resetMatchingServices();
              } else {
                updateExecutionField('wasteId')(value);
              }
            }}
            value={execution.wasteId}
            items={wastes}
          />
        ) : (
          <MaterialBasicTextfield
            label={t('fe.executionModal.executionInfosForm.serviceTrashLabel')}
            color="secondary"
            placeholder={t('fe.executionModal.executionInfosForm.serviceTrashPlaceholder')}
            value={formatTranslatedName(
              execution.dto?.service?.waste?.nameTranslateKey,
              execution.dto?.service?.waste?.name
            )}
            disabled
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {containers?.length && canEditService ? (
          <MaterialDropdownForm
            label={t('fe.executionModal.executionInfosForm.serviceContainerLabel')}
            color="secondary"
            placeholder={t('fe.executionModal.executionInfosForm.serviceContainerPlaceholder')}
            disabled={isFirstExecLocked}
            enableSearch={true}
            handleChange={(value) => {
              if (!value) {
                createExecutionService.setExecutionDetail({
                  ...execution,
                  wasteId: undefined,
                  containerId: undefined,
                  serviceId: undefined,
                });
                createExecutionService.resetMatchingServices();
              } else {
                updateExecutionField('containerId')(value);
              }
            }}
            value={execution.containerId}
            items={containers}
          />
        ) : (
          <MaterialBasicTextfield
            label={t('fe.executionModal.executionInfosForm.serviceContainerLabel')}
            color="secondary"
            placeholder={t('fe.executionModal.executionInfosForm.serviceContainerPlaceholder')}
            value={formatTranslatedName(
              execution.dto?.service?.container?.nameTranslateKey,
              execution.dto?.service?.container?.name
            )}
            disabled
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {services?.length && canEditService ? (
          <MaterialDropdownForm
            enableSearch={true}
            label={t('fe.executionModal.executionInfosForm.service')}
            color="secondary"
            disabled={isFirstExecLocked}
            placeholder={t('fe.executionModal.executionInfosForm.servicePlaceholder')}
            handleChange={(value) => {
              if (!value) {
                createExecutionService.setExecutionDetail({
                  ...execution,
                  wasteId: undefined,
                  serviceId: undefined,
                  containerId: undefined,
                });
                createExecutionService.resetMatchingServices();
              } else {
                const service = services?.find((s) => s.id === value);
                if (service) {
                  createExecutionService.setExecutionDetail({
                    ...execution,
                    wasteId: service.waste.id,
                    containerId: service.container.id,
                  });
                  createExecutionService.setServiceId(
                    execution.id,
                    service.waste.id,
                    service.container.id,
                    service.id
                  );
                }
              }
            }}
            value={execution.serviceId}
            items={services.map(
              (s): MaterialDropdownItem => ({
                label: s.clientWasteName,
                value: s.id,
              })
            )}
          />
        ) : (
          <MaterialBasicTextfield
            label={t('fe.executionModal.executionInfosForm.service')}
            color="secondary"
            placeholder={t('fe.executionModal.executionInfosForm.servicePlaceholder')}
            value={execution.dto?.service.clientWasteName}
            disabled
          />
        )}
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Button onClick={() => setShowSelectService(true)} variant="outlined" size="large">
          {t('fe.executionModal.executionInfosForm.seeAllServicesButton')}
        </Button>
      </Grid>
      {!execution.serviceId &&
        !haulingDestinationLoading &&
        execution.containerId &&
        execution.wasteId &&
        matchingServices &&
        matchingServices.length > 1 && (
          <Grid item xs={12}>
            <Alert icon={false} severity="warning">
              {t('fe.executionModal.executionInfosForm.warningMultipleServicesMatches')}
            </Alert>
          </Grid>
        )}
      {showSelectService && (
        <SelectServiceModal
          services={allServices || []}
          wastes={wastes}
          containers={containers}
          handleClose={() => setShowSelectService(false)}
          handleSelectService={(service) => {
            if (service) {
              createExecutionService.setExecutionDetail({
                ...execution,
                wasteId: service.waste.id,
                containerId: service.container.id,
              });
              createExecutionService.setServiceId(
                execution.id,
                service.waste.id,
                service.container.id,
                service.id
              );
            }
            setShowSelectService(false);
          }}
        />
      )}
    </>
  );
};

export default ExecutionServiceField;
