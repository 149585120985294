import { QueryEntity } from '@datorama/akita';
import { WastesDashboardStore, wastesDashboardStore, WasteState } from './wastes-dashboard.store';
import { map } from 'rxjs/operators';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export class WastesDashboardQuery extends QueryEntity<WasteState> {
  constructor(protected store: WastesDashboardStore) {
    super(store);
  }

  wastesDropdownItems$ = this.selectAll().pipe(
    map((wastes) =>
      wastes.map(
        (waste): MaterialDropdownItem => ({
          value: waste?.id,
          label: `${waste.wasteName} (${waste.wasteCount})`,
        })
      )
    )
  );
}

export const wastesDashboardQuery = new WastesDashboardQuery(wastesDashboardStore);
