import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';
import { operationScreenStatsService } from '../operation-screen-stats.service';
import { operationScreenStatsQuery } from '../operation-screen-stats.query';
import { useObservable } from '@ngneat/react-rxjs';
import { filtersQuery } from 'store/common/filters/filters.query';

export const useOperationScreenStats = (from: Date, to: Date, wasteIds: string[]) => {
  const { enqueueSnackbar } = useSnackbar();
  const from$ = useRef(new Subject<Date>()).current;
  const to$ = useRef(new Subject<Date>()).current;
  const wasteIds$ = useRef(new Subject<string[]>()).current;
  const [issueCountStats] = useObservable(operationScreenStatsQuery.getIssueCountStats$);
  const [issueKinds] = useObservable(operationScreenStatsQuery.getIssueKindsStats$);
  const [quantityAverage] = useObservable(operationScreenStatsQuery.getWasteQuantityStats$);
  const [pickUpDone] = useObservable(operationScreenStatsQuery.getPickUpDoneStats$);
  const [isIssueCountLoading] = useObservable(operationScreenStatsQuery.isIssueCountLoading$);
  const [isIssueKindsLoading] = useObservable(operationScreenStatsQuery.isIssueKindsLoading$);
  const [isQuantityAverageLloading] = useObservable(
    operationScreenStatsQuery.isAverageQuantityLoading$
  );
  const [isPickUpDoneLoading] = useObservable(operationScreenStatsQuery.isPickUpDoneLoading$);
  const [isEvolutionOperationLoading] = useObservable(
    operationScreenStatsQuery.isOperationLoading$
  );
  const [operationEvolutionStats] = useObservable(
    operationScreenStatsQuery.getEvolutionOperationStats$
  );
  useEffect(() => {
    const _queryParams = combineLatest([
      from$,
      to$,
      wasteIds$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
      filtersQuery.currentFilters$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([from, to, wasteIds, organisationId, orgUnitId, currentFilters]) => {
        if (organisationId) {
          operationScreenStatsService
            .fetchstats(from, to, wasteIds, organisationId, orgUnitId, currentFilters)
            .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
        }
      });
    return () => {
      _queryParams.unsubscribe();
    };
  }, []);

  useEffect(() => wasteIds$.next(wasteIds), [wasteIds]);

  useEffect(() => from$.next(from), [from]);

  useEffect(() => to$.next(to), [to]);

  return {
    isIssueCountLoading,
    issueCountStats,
    isIssueKindsLoading,
    issueKinds,
    quantityAverage,
    isQuantityAverageLloading,
    pickUpDone,
    isPickUpDoneLoading,
    isEvolutionOperationLoading,
    operationEvolutionStats,
  };
};
