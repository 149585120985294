import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';

import { useObservable } from '@ngneat/react-rxjs';
import { filtersQuery } from 'store/common/filters/filters.query';
import { financeScreenStatsQuery } from '../finance-screen-stats.query';
import { financeScreenStatsService } from '../finance-screen-stats.service';

export const useFinanceScreenStats = (
  from: Date,
  to: Date,
  wasteIds: string[],
  dateFilterKind: 'OPERATIONS' | 'INVOICING'
) => {
  const { enqueueSnackbar } = useSnackbar();
  const from$ = useRef(new Subject<Date>()).current;
  const to$ = useRef(new Subject<Date>()).current;
  const wasteIds$ = useRef(new Subject<string[]>()).current;
  const dateFilterKind$ = useRef(new Subject<string>()).current;
  const [expenseValoEvolStats] = useObservable(
    financeScreenStatsQuery.getExpenseValoEvolutionStats$
  );
  const [isExpenseValoEvolLoading] = useObservable(
    financeScreenStatsQuery.isExpenseValoEvolutionLoading$
  );
  const [serviceExpenseStats] = useObservable(financeScreenStatsQuery.getServiceExpenseStats$);
  const [isServiceExpenseLoading] = useObservable(financeScreenStatsQuery.isServiceExpenseLoading$);

  const [wasteExpenseValoStats] = useObservable(financeScreenStatsQuery.getwasteExpenseValoStats$);
  const [isWasteExpenseValoLoading] = useObservable(
    financeScreenStatsQuery.isWasteExpenseValoLoading$
  );

  useEffect(() => {
    const _queryParams = combineLatest([
      from$,
      to$,
      wasteIds$,
      dateFilterKind$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitId$,
      filtersQuery.currentFilters$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(
        ([
          from,
          to,
          wasteIds,
          dateFilterKind,
          organisationId,
          orgUnitId,
          currentFilters,
        ]: any[]) => {
          if (organisationId) {
            financeScreenStatsService
              .fetchstats(
                from,
                to,
                wasteIds,
                dateFilterKind,
                organisationId,
                orgUnitId,
                currentFilters
              )
              .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
          }
        }
      );
    return () => {
      _queryParams.unsubscribe();
    };
  }, []);

  useEffect(() => wasteIds$.next(wasteIds), [wasteIds]);

  useEffect(() => from$.next(from), [from]);

  useEffect(() => to$.next(to), [to]);

  useEffect(() => dateFilterKind$.next(dateFilterKind), [dateFilterKind]);

  return {
    expenseValoEvolStats,
    isExpenseValoEvolLoading,
    serviceExpenseStats,
    isServiceExpenseLoading,
    isWasteExpenseValoLoading,
    wasteExpenseValoStats,
  };
};
