import { FC, useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { IOuter } from './App.types';
import useDevice from 'utils/hooks/useDevice';
import { WindowSizeContextProvider } from 'utils/hooks/useWindowSize';
import Rollbar from 'rollbar';
import { SnackbarProvider } from 'notistack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { RoutePath } from 'constants/Routes';
import DemoPage from '../Demo';
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { msalInstance, msalLoginRequest, requestAzureAccessToken } from 'utils/azure-config';
import AzureRedirectScreen from 'screens/azure/redirect/AzureRedirect.screen';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useSnackBarNotifier } from 'store/snackbar/hooks/useSnackBarNotifier';
import { localeMap } from 'utils/data/date.utils';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
// import DateAdapter from '@mui/lab/AdapterDateFns';
import { getCurrentLanguage } from 'utils/data/i18n.utils';
import { useTranslation } from 'react-i18next';
import { updateDayjsLanguage } from 'utils/data/dayjs.utils';
import { PopupServiceProvider } from '../Modal/Popup/PopupService';
import Authed from 'screens/auth/Authed';
import { LicenseInfo } from '@mui/x-license-pro';
import LoadingScreen from 'screens/azure/loading/Loading.screen';
import AuditProposalsScreen from 'screens/audit/AuditProposal.screen';

LicenseInfo.setLicenseKey(
  '463ec2068c655775717fb6dccf8c6a13Tz03Mjg5NSxFPTE3MjM4ODUxOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const rollbarConfig = new Rollbar({
  enabled: typeof process.env.REACT_APP_ROLLBAR_TOKEN !== 'undefined',
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

const App = () => {
  const { isDesktop } = useDevice();
  useSnackBarNotifier();
  useState(rollbarConfig);

  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (inProgress === InteractionStatus.None) {
      requestAzureAccessToken();
    }
  }, [isAuthenticated, inProgress]);

  return (
    <Switch>
      <Route path={RoutePath.AuditProposals}>
        <AuditProposalsScreen />
      </Route>
      <Route path={RoutePath.Demo}>
        <DemoPage />
      </Route>
      <Route exact path={RoutePath.AzureRedirect}>
        <AzureRedirectScreen />
      </Route>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={msalLoginRequest}
        loadingComponent={LoadingScreen}
      >
        <Authed />
      </MsalAuthenticationTemplate>
    </Switch>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RouterWrapper: FC<IOuter> = ({ history }) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateDayjsLanguage();
  }, [t]);

  return (
    <SnackbarProvider
      style={{ backgroundColor: '#172134' }}
      maxSnack={3}
      autoHideDuration={3000}
      iconVariant={{
        success: <CheckCircleOutlineIcon color="primary" style={{ marginRight: 10 }} />,
        error: <HighlightOffIcon color="error" style={{ marginRight: 10 }} />,
        info: <ErrorOutlineIcon color="warning" style={{ marginRight: 10 }} />,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[getCurrentLanguage()]}>
        <Router history={history}>
          <MsalProvider instance={msalInstance}>
            <WindowSizeContextProvider>
              <PopupServiceProvider>
                <App />
              </PopupServiceProvider>
            </WindowSizeContextProvider>
          </MsalProvider>
        </Router>
      </LocalizationProvider>
    </SnackbarProvider>
  );
};

export default RouterWrapper;
