import { AddressDto } from './AddressDto';
import {
  ClientOrganisationUnitDto,
  formatClientOrgUnitDtoToOrganisationUnit,
} from './ClientOrganisationUnitDto';
import { UserLightDto } from '../user/UserLightDto';
import { Organisation } from 'store/organisation/organisations';

export interface ClientDto {
  id: number;
  users: UserLightDto[];
  name: string;
  clientOrganisationUnits: ClientOrganisationUnitDto[];
  address: AddressDto;
  invoicingSource: string;
}

export function formatClientDtoToOrganisation({
  id,
  users,
  name,
  clientOrganisationUnits,
  address,
  invoicingSource,
}: ClientDto): Organisation {
  return {
    id,
    users,
    name,
    organisationUnits: clientOrganisationUnits.map(formatClientOrgUnitDtoToOrganisationUnit),
    address,
    invoicingSource,
  };
}
