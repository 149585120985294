// ⚠️ Keys cannot have those characters: `/`, `#`, `(` `)`
// If those characters would be used suffix system would get broken!

import { ID } from '@datorama/akita';

export enum LocalStorageKey {
  Locale = 'locale',
  AccessToken = 'access_token',
  AlertMessageKeyRead = 'alert_message_key_read7',
}

export enum LocalStorageKeyJson {
  ClientSiteData = 'client_and_site',
  OrganisationId = 'organisation_id',
  OrganisationUnitId = 'organisation_unit_id',
  NotificationQueryParams = 'notification_query_params',
}

export type TJsonDataTypes = {
  [LocalStorageKeyJson.ClientSiteData]: {
    clientId?: number;
    siteId?: number;
  };
  [LocalStorageKeyJson.OrganisationId]: {
    organisationId?: ID;
  };
  [LocalStorageKeyJson.OrganisationUnitId]: {
    orgUnitId?: ID;
  };
  [LocalStorageKeyJson.NotificationQueryParams]: {
    executionId?: string | null;
    issueId?: string | null;
  };
};
