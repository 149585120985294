import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WasteDashboard } from './wastes-dashboard.model';

export type WasteState = EntityState<WasteDashboard>;

@StoreConfig({
  name: 'wastes-dashboard',
  resettable: true,
})
export class WastesDashboardStore extends EntityStore<WasteState> {
  constructor() {
    super();
  }
}

export const wastesDashboardStore = new WastesDashboardStore();
