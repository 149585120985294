import { ID, QueryEntity } from '@datorama/akita';
import {
  OrganisationUnitsState,
  organisationUnitsStore,
  OrganisationUnitsStore,
} from './organisation-units.store';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { formatAddress } from 'api/dto/client/AddressDto';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class OrganisationUnitsQuery extends QueryEntity<OrganisationUnitsState> {
  constructor(protected store: OrganisationUnitsStore) {
    super(store);
  }

  orgUnits$ = this.selectAll();
  orgUnitsAsAutocomplete$ = this.selectAll().pipe(
    map((orgUnits) =>
      orgUnits
        .map(
          (orgUnit): AutocompleteData => ({
            id: orgUnit.id,
            title: orgUnit.name,
            description: formatAddress(orgUnit.address),
          })
        )
        .sort((a, b) => a.title.localeCompare(b.title))
    )
  );
  activeOrgUnit$ = combineLatest([this.orgUnits$, this.selectActiveId()]).pipe(
    map(([orgUnits, activeId]) => orgUnits.find((it) => it.id === activeId))
  );
  activeOrgUnitId$ = this.select((state) => state.activeOrgUnitId);

  filterOrgUnits = (
    orgUnitsAutocomplete: AutocompleteData[],
    filterString: string
  ): AutocompleteData[] => {
    return orgUnitsAutocomplete.filter((it) =>
      it.title.toLowerCase().includes(filterString.toLowerCase())
    );
  };

  get activeOrgUnitId(): ID | undefined {
    return this.getValue().activeOrgUnitId;
  }
}

export const organisationUnitsQuery = new OrganisationUnitsQuery(organisationUnitsStore);
