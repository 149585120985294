import { OperationExecutionsStore, operationExecutionsStore } from './operation-executions.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import SnackError from 'utils/models/snackError';
import env from 'utils/env';
import { FilterOrderBy } from './enums/filter-order-by';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';
import { OperationFilters } from 'store/operations/operationExecutions/hooks/useFetchExecutionSearch';

export class OperationExecutionsService {
  constructor(private store: OperationExecutionsStore) {}

  fetchExecutionCountByStatus = async (
    searchString: string,
    from?: Date,
    to?: Date,
    orgId?: ID,
    orgUnitId?: ID
  ) => {
    if (!orgId) {
      return;
    }
    const [status, e] = await resolve(
      metawasteApi.getCountByStatus(
        orgId,
        orgUnitId,
        searchString.length ? searchString : undefined,
        from,
        to
      )
    );
    if (e || !status) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    this.store.update((state) => ({
      ...state,
      status,
    }));
  };

  fetchExecutionSearch = async (
    page: number,
    sortBy?: string,
    orderBy?: FilterOrderBy,
    orgId?: ID,
    orgUnitId?: ID,
    filters?: OperationFilters,
    currentFilters?: TableFilter[]
  ) => {
    if (!orgId) {
      this.store.setLoading(false);
      return;
    }
    this.store.set([]);
    this.store.setLoading(true);
    const [search, e] = await resolve(
      metawasteApi.getSearchExecution(
        env.PAGINATION,
        page + 1,
        orderBy && sortBy ? { sortBy, orderBy } : undefined,
        orgId,
        orgUnitId,
        filters,
        toRequestFilters(currentFilters || [])
      )
    );

    this.store.setLoading(false);
    if (e || !search) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    this.store.update((state) => ({
      ...state,
      total: search.total,
    }));
    this.store.set(search.data);
  };
}

export const operationExecutionsService = new OperationExecutionsService(operationExecutionsStore);
