import { useEffect, useState } from 'react';
import { useObservable } from 'react-use';
import { filtersQuery } from 'store/common/filters/filters.query';
import { Issue, IssueStatus } from '../issues.model';
import { issueService } from '../issues.service';
import { GridSortDirection, GridSortItem } from '@mui/x-data-grid-pro';
import { ID } from '@datorama/akita';

interface useGetIssuesProps {
  from?: Date;
  to?: Date;
  issueStatus?: IssueStatus;
  organisationUnitId: ID | undefined;
  organisationId?: ID;
}

export const useGetIssues = (props: useGetIssuesProps) => {
  const { from, to, issueStatus, organisationUnitId, organisationId } = props;

  const [issues, setIssues] = useState<Issue[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(50);
  const [sort, setSort] = useState<{ field: string; sort: GridSortDirection }>({
    field: '',
    sort: 'asc',
  });
  const [filters] = useObservable(filtersQuery.currentFilters$) ?? [];

  useEffect(() => {
    const getIssues = async () => {
      const issues = await issueService.getIssue(
        page,
        limitPerPage,
        { field: sort.field, sort: sort.sort },
        organisationUnitId,
        organisationId,
        issueStatus,
        from,
        to,
        search,
        [filters]
      );
      setIssues(issues.data ?? []);
      setLoading(false);
      setTotal(issues?.total || 0);
    };

    setIssues([]);
    setLoading(true);
    getIssues().catch((err) => console.log(err));
  }, [
    from,
    to,
    page,
    filters,
    limitPerPage,
    search,
    sort,
    issueStatus,
    organisationUnitId,
    organisationId,
  ]);

  const handlechangePage = (page: number) => setPage(page);

  const handleSearch = (search: string) => setSearch(search);

  const handleSort = (newModel: GridSortItem) =>
    setSort({ field: newModel.field, sort: newModel.sort });

  const handlePageSizeChange = (pageSize: number) => setLimitPerPage(pageSize);

  return {
    issues,
    loading,
    option: {
      page,
      sort,
      total,
      limitPerPage,
      handlechangePage,
      handleSearch,
      handleSort,
      handlePageSizeChange,
    },
  };
};
