import { Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import style from './AppBarDropdown.style';

import { WithStyles, withStyles } from '@mui/styles';
import SearchDropdownBase from 'components/SearchDropdownBase';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { ID } from '@datorama/akita';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';

interface AppBarDropdownProps extends WithStyles<typeof style> {
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  color?: 'primary' | 'secondary';
  error?: string;
  handleChange?: (value?: ID) => void;
  handleSearchChange?: (value: string) => void;
  isLoadingSearch?: boolean;
  placeholder?: string;
  value?: AutocompleteData;
  data: AutocompleteData[];
  icon?: React.ReactElement;
  bottomComponent?: React.ReactElement;
}

const AppBarDropdown: FC<AppBarDropdownProps> = (props: AppBarDropdownProps) => {
  const {
    classes,
    label,
    required,
    readOnly,
    color = 'primary',
    fullWidth = true,
    error,
    handleChange,
    handleSearchChange,
    isLoadingSearch,
    placeholder,
    value,
    data,
    icon,
    bottomComponent,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const searchInput = React.useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={searchInput}
      className={classes.boxContainer}
      onClick={(e) => {
        e.stopPropagation();
        !anchorEl ? setAnchorEl(searchInput.current) : setAnchorEl(null);
      }}
    >
      <div className={classes.leftContainer}>
        {icon}
        <div className={classes.appBarContainer}>
          {value?.title && (
            <Typography
              fontSize={14}
              className={classes.typography}
              style={{ whiteSpace: 'nowrap' }}
            >
              {value.title}
            </Typography>
          )}
          {value?.description && (
            <Typography
              variant="subtitle2"
              fontSize={12}
              className={classes.hideForMobile}
              style={{ whiteSpace: 'nowrap' }}
            >
              {value?.description}
            </Typography>
          )}
          {!value && (
            <Typography
              variant="subtitle2"
              fontSize={12}
              className={classes.typography}
              style={{ whiteSpace: 'nowrap' }}
            >
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
      <ArrowDropDown style={{ transform: anchorEl ? 'rotate(180deg)' : undefined }} />
      <SearchDropdownBase
        bottomComponent={bottomComponent}
        // popupState={popupState}
        anchorEl={anchorEl}
        data={data}
        value={value}
        isLoadingSearch={isLoadingSearch}
        placeholderSearch={t('fe.appBar.clientSearchPlaceholder')}
        handleClose={() => {
          setAnchorEl(null);
        }}
        handleSearchChange={(value) => {
          handleSearchChange?.(value);
        }}
        handleChange={(value) => {
          handleChange?.(value);
          setAnchorEl(null);
        }}
      />
    </div>
  );
};

export default withStyles(style)(AppBarDropdown);
