import React, { useState } from 'react';
import {
  Box,
  Button,
  createTheme,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Close } from 'assets/icons/index';

// import { Sizes } from '@constants/sizes.constants';
import AsyncMultiSelectWithSearchComponent from '../Select/AsyncMultiSelectWithSearchComponent';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableFilter, TableFilterType } from '../Select/SelectItem.interface';
import { filtersService } from 'store/common/filters/filters.service';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { ThemeProvider } from '@mui/styles';
import { filtersTheme } from 'components/Filters/Filters.theme';

interface TableFiltersDrawerProps {
  filters: TableFilter[];
  handleClose: () => void;
  handleSave: (tableFilters: TableFilter[]) => void;
}

const TableFiltersDrawer = (props: TableFiltersDrawerProps) => {
  const { filters, handleClose, handleSave } = props;

  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<TableFilter[]>(filters);

  const updateFilters = (name: string, filterIndex: number) => (value: any) => {
    const filtersToUpdate = [...(newFilters || [])];
    filtersToUpdate[filterIndex] = { ...filtersToUpdate[filterIndex], [name]: value };
    setNewFilters(filtersToUpdate);
  };

  const orgId = organisationsQuery.activeOrganisationId;
  const orgUnitId = organisationUnitsQuery.activeOrgUnitId;

  return (
    <Drawer anchor="right" open onClose={handleClose}>
      <ThemeProvider
        theme={(theme: Theme) =>
          createTheme({
            ...theme,
            components: {
              ...theme.components,
              ...filtersTheme,
            },
          })
        }
      >
        <Stack flex={1} width="100%" overflow="hidden">
          <Stack mr="10px">
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb="12px"
                mr="-8px"
              >
                <Typography fontSize={18} fontWeight="700">
                  {t('global.filters')}
                </Typography>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
              <Divider variant="fullWidth" orientation="horizontal" />
            </Stack>
            <Divider variant="fullWidth" orientation="horizontal" />
          </Stack>
          <Stack pt="20px" flex={1} spacing={1} overflow="hidden">
            <Stack spacing={3} overflow="auto" className="scrollable" py="20px" pr="10px">
              {newFilters.map((filter, index) => {
                if (filter.hide) return null;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Stack key={`Table filter ${filter.type} - ${index}`} mr="-5px">
                    <Stack pr="5px">
                      <AsyncMultiSelectWithSearchComponent
                        // labelWidth={Sizes.drawerInputLabelWidth}
                        labelWidth={140}
                        label={t(`global.filterFieldType.${filter.type}`)}
                        placeholder={t('global.selectOptions')}
                        readOnly={filter.disabled}
                        handleChange={updateFilters('values', index)}
                        values={filter.values ?? []}
                        getOptions={(search) => {
                          let searchObservable: Observable<any> = of([]);

                          switch (filter.type) {
                            case TableFilterType.SUPPLIER_ORGANISATION:
                              searchObservable = filtersService.fetchSuppliersOrganisation({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            case TableFilterType.SUPPLIER_ORGANISATION_UNIT:
                              searchObservable = filtersService.fetchSupplierOrganisationUnits({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            case TableFilterType.CLIENT_ORGANISATION:
                              searchObservable = filtersService.fetchClientsOrganisation({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            case TableFilterType.CLIENT_ORGANISATION_UNIT:
                              searchObservable = filtersService.fetchClientsOrganisationUnit({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            case TableFilterType.SERVICE_KIND:
                              return filtersService
                                .fetchServiceKinds({
                                  orgId,
                                  orgUnitId,
                                  searchString: search,
                                })
                                .pipe(
                                  map((options) =>
                                    options.map((o) => ({ label: o.kind || '-', value: o.id }))
                                  )
                                );
                            case TableFilterType.WASTE:
                              // const wasteFamilyIds = newFilters
                              //   .find((of) => of.type === TableFilterType.WASTE_FAMILY)
                              //   ?.values?.map((v) => v.value);
                              searchObservable = filtersService.fetchWastes({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            // case TableFilterType.WASTE_FAMILY:
                            //   searchObservable = wasteFamiliesService.searchWasteFamilies(search);
                            //   break;
                            case TableFilterType.CONTAINER:
                              searchObservable = filtersService.fetchContainers({
                                orgId,
                                orgUnitId,
                                searchString: search,
                              });
                              break;
                            // case TableFilterType.ORGANISATION_TYPOLOGY:
                            //   return of(
                            //     OrganisationKind.selectItems().filter(
                            //       (o) =>
                            //         !search || o.label.toLowerCase().includes(search.toLowerCase())
                            //     )
                            //   );
                            // case TableFilterType.ORGANISATION_DOMAIN:
                            //   return of(
                            //     Domain.selectItems().filter(
                            //       (o) =>
                            //         !search || o.label.toLowerCase().includes(search.toLowerCase())
                            //     )
                            //   );
                          }
                          return searchObservable?.pipe(
                            map((options) =>
                              options.map((o) => ({ label: o.name || '-', value: o.id }))
                            )
                          );
                        }}
                      />
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Box alignSelf="flex-end" justifySelf="flex-end" my="20px" mr="10px">
            <Button variant="contained" color="primary" onClick={() => handleSave(newFilters)}>
              {t(`global.modify`)}
            </Button>
          </Box>
        </Stack>
      </ThemeProvider>
    </Drawer>
  );
};

export default TableFiltersDrawer;
