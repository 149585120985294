import { alpha } from '@mui/material';
import { SelectItem } from 'components/Select/SelectItem.interface';
import { Colors } from 'constants/Colors';
import i18n from 'utils/data/i18n.utils';

export interface UserLight {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface DocumentInfo {
  id: number;
  name: string;
  description: string;
  documentKind: string;
  filename: string;
  mimetype: string;
  file?: File;
}

export interface IssueKind {
  id: number;
  name: string;
  type: string;
}

export interface IssueDetails {
  id?: number;
  author?: UserLight;
  issueSignature?: string;
  status?: IssueStatus;
  documents?: DocumentInfo[];
  executionSignature?: string;
  issueKind?: IssueKind;
  reportedAt?: Date;
  description?: string;
  updatedAt?: Date;
}

export const toManageIssue = (issue: IssueDetails): ManageIssue => ({
  ...issue,
  issueKind: issue.issueKind,
  dateHour: issue.reportedAt,
});

export enum IssueStatus {
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
}

export const IssueStatusSelectItems = (): SelectItem[] => [
  {
    label: i18n.t('database.issueStatus.CREATED'),
    value: IssueStatus.CREATED,
    backgroundColor: alpha(Colors.textWithOpacity, 0.5),
  },
  {
    label: i18n.t('database.issueStatus.CLOSED'),
    value: IssueStatus.CLOSED,
    backgroundColor: alpha(Colors.primary, 0.5),
  },
];

export enum IssueCategory {
  QUALITY = 'QUALITY',
  SECURITY = 'SECURITY',
  ENVIRONMENT = 'ENVIRONMENT',
  HEALTH = 'HEALTH',
  OTHER = 'OTHER',
}

export const IssueCategorySelectItems = (): SelectItem[] => [
  { label: i18n.t('global.issueCategory.quality'), value: IssueCategory.QUALITY },
  { label: i18n.t('global.issueCategory.security'), value: IssueCategory.SECURITY },
  { label: i18n.t('global.issueCategory.environment'), value: IssueCategory.ENVIRONMENT },
  { label: i18n.t('global.issueCategory.health'), value: IssueCategory.HEALTH },
  { label: i18n.t('global.issueCategory.other'), value: IssueCategory.OTHER },
];

export interface ManageIssue {
  id?: number;
  author?: UserLight;
  issueSignature?: string;
  status?: IssueStatus;
  resolution?: string;
  documents?: DocumentInfo[];
  documentsToAdd?: DocumentInfo[];
  documentsToDelete?: DocumentInfo['id'][];
  executionId?: number;
  executionSignature?: string;
  issueKind?: IssueKind;
  reportedAt?: Date;
  dateHour?: Date;
  resolvedDateHour?: Date;
  resolver?: UserLight;
  resolvedAt?: Date;
  description?: string;
  updatedAt?: Date;
  issueCategory?: IssueCategory;
  clientOrganisationUnitId?: number;
  supplierOrganisationUnitId?: number;
  organisationType?: string;
  organisationUnit?: number;
  phoneNumber?: string;
  clientOrganisationUnit?: {
    id: number;
    name: string;
    clientOrganisation: { id: number; name: string };
  };
  supplierOrganisationUnit?: {
    id: number;
    name: string;
    supplierOrganisation: { id: number; name: string };
  };
}

export interface Issue {
  id: number;
  reportedAt: Date;
  type: string;
  comment: string;
  executionId: number;
  executionTicketSignature: string;
  waste: string;
  container: string;
  status: IssueStatus;
  clientOrganisationName?: string;
  clientOrganisationUnitName?: string;
  supplierOrganisationName?: string;
  supplierOrganisationUnitName?: string;
}
