import { guid, ID } from '@datorama/akita';
import { ExecutionIssueDto } from 'api/dto/execution/ExecutionIssueDto';

export interface IssueDetailsUi {
  id: ID;
  issueKindId?: ID;
  reportedAtDate?: Date | null;
  reportedAtTime: Date | null;
  description?: string;
  documents: number[];
  status?: string;
  dto?: ExecutionIssueDto;
  executionId?: ID;
  phoneNumber?: string;
}

export function createNewIssueDetailsUi(): IssueDetailsUi {
  return {
    id: guid(),
    reportedAtDate: new Date(),
    reportedAtTime: new Date(),
    documents: [],
  };
}

export function createNewIssueDetailsFromDto(
  dto: ExecutionIssueDto,
  executionId: ID
): IssueDetailsUi {
  return {
    id: dto.id,
    issueKindId: dto.issueKind.id,
    reportedAtDate: dto.reportedAt,
    reportedAtTime: dto.reportedAt,
    status: dto.status,
    documents: [],
    description: dto.description,
    dto,
    executionId,
  };
}
