import { ID } from '@datorama/akita';
import { AddressDto } from 'api/dto/client/AddressDto';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export interface OrganisationUnit {
  id: ID;
  name: string;
  address: AddressDto;
}

export const organisationUnitToMaterialDropdownItem = (
  organisationUnit: OrganisationUnit
): MaterialDropdownItem => {
  return {
    value: organisationUnit.id,
    label: organisationUnit.name,
  };
};
