import React from 'react';
import { Checkbox, InputLabel } from '@mui/material';
import { useMaterialCheckBoxStyles } from './MaterialCheckBox.style';

interface MaterialCheckBoxProps {
  handleChange?: (checked: boolean) => void;
  label?: string;
  labelPlacement?: 'start' | 'end';
  labelWidth?: number;
  readOnly?: boolean;
  value?: boolean;
}

const MaterialCheckBox = (props: MaterialCheckBoxProps) => {
  const { handleChange, label, labelPlacement = 'start', labelWidth, readOnly, value } = props;
  const classes = useMaterialCheckBoxStyles();

  return (
    <div className={classes.boxContainer}>
      {!!label && <InputLabel className={classes.inputLabel}>{label}</InputLabel>}
      <Checkbox
        onChange={handleChange ? (evt, checked) => handleChange(checked) : undefined}
        checked={value}
        readOnly={readOnly}
      />
    </div>
  );
};

export default MaterialCheckBox;
