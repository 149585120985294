import React, { useState } from 'react';
import { InputLabel } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
// import { CalendarLineIcon } from 'assets/icons/calendarLine';
import style from './MaterialSingleDatePicker.style';
import MaterialDatePickerBase from '../MaterialDatePickerBase';

interface MaterialSingleDatePickerProps extends WithStyles<typeof style> {
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  withIcon?: boolean;
  color?: 'primary' | 'secondary' | 'edit-table';
  handleChange: (value: any | null) => void;
  disabled?: boolean;
  readOnly?: boolean;
  value?: Date | null;
  error?: string;
  minDate?: Date;
  customStyle?: React.CSSProperties;
}

const MaterialSingleDatePicker = (props: MaterialSingleDatePickerProps) => {
  const {
    classes,
    withIcon,
    color = 'primary',
    error,
    fullWidth = true,
    handleChange,
    label,
    disabled,
    readOnly,
    required,
    minDate,
    value = null,
  } = props;
  const [openPicker, setOpenPicker] = useState(false);

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <MaterialDatePickerBase {...props} className={classes.textfield} withIcon={withIcon} />
    </div>
  );
};

export default withStyles(style)(MaterialSingleDatePicker);
