import { WastesDashboardStore, wastesDashboardStore } from './wastes-dashboard.store';
import { metawasteApi } from 'api/Axios';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import { ID } from '@datorama/akita';
import { wasteDtoToFormat } from 'api/dto/dashboard/wastes/WasteDashboardDto';
import i18n from 'utils/data/i18n.utils';

export class WastesDashboardService {
  constructor(private store: WastesDashboardStore) {}

  fetchWastes = async (from: Date, to: Date, organisationId: ID, orgUnitId?: ID) => {
    const [wasteDto, e] = await resolve(
      metawasteApi.getWastes(from, to, organisationId, orgUnitId)
    );
    if (!wasteDto || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.wastes'));
    }
    this.store.set(wasteDto.map(wasteDtoToFormat));
  };
}

export const wastesDashboardService = new WastesDashboardService(wastesDashboardStore);
